import styled from 'styled-components';

export const Container = styled.div<{ layoutType?: string }>`
    background-color: ${({ theme }) => theme.palette.common.aliceBlueSub4};
    padding: ${({ theme }) => theme.spacing.components.spacing1};
`;

export const ContentWrapper = styled.div`
    background-color: ${({ theme }) => theme.palette.common.aliceBlueSub4};
    padding: 0px 0px 20px 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    width: 100%;
    flex-grow: 1;
`;

export const TabPaneContainer = styled.div`
    width: calc(100% - 260px);
    flex-grow: 1;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.palette.common.gallery};
    border-radius: ${({ theme }) => theme.roundRadius.radius1};
    background-color: ${({ theme }) => theme.palette.background.block};
`;

export const TopFilter = styled.div`
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    height: 50px;
`;

export const HLabel = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #444444;
`;

export const HRight = styled.div``;
