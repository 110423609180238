import { Palette } from '../../../models/theme';
import { colors } from '../../colors';

export const palette: Palette = {
    common: colors,
    primary: {
        main: colors.shakespeare,
        dark: colors.astral,
        light: colors.shakespeareLight,
    },
    secondary: {
        main: colors.persianGreen,
        dark: '',
        light: '',
    },
    warning: {
        main: colors.vividTangerine,
        dark: '',
        light: '',
    },
    success: {
        main: colors.persianGreen,
        dark: '',
        light: '',
    },
    error: {
        main: colors.wildWatermelon,
        dark: '',
        light: '',
    },
    background: {
        menu: colors.blueBayoux,
        body: colors.alabaster,
        block: colors.white,
    },
    text: {
        primary: colors.mineShaft,
        secondary: colors.pictonBlue,
        disabled: colors.mercury,
        selected: colors.tundora,
    },
    action: {
        disabled: colors.mercury,
        inactive: colors.dustyGray,
        focus: '',
        hover: '',
        selected: '',
    },
};
