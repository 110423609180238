import { ElementType, useState } from 'react';
import { ComponentProps } from '../../common/types';
import logoIconSrc from './icons/logo.svg';
import * as Styles from './styles';
import { useTheme } from 'styled-components';
import { Tooltip } from '../tooltip';
import { Typography } from '../typography';
import { ReactComponent as DashboardIcon } from './icons/dashboard.svg';
import { ReactComponent as SettingIcon } from './icons/settings.svg';
import { ReactComponent as ConnectionsIcon } from './icons/connections.svg';
import { ReactComponent as RectangleIcon } from './icons/rectangle.svg';
import { ReactComponent as HelpIcon } from './icons/help.svg';
import { ReactComponent as ProfileIcon } from './icons/profile.svg';
export enum NavbarItemType {
    Link,
    Dropdown,
}

export class NavbarLink {
    public readonly type = NavbarItemType.Link;
    constructor(public label: string, public url: string, public Icon?: ElementType) {}
}

export class NavbarDropdown {
    public readonly type = NavbarItemType.Dropdown;
    constructor(public label: string, public Icon: ElementType, public subItems: NavbarLink[]) {}
}

export type NavigationItem = NavbarLink | NavbarDropdown;

export interface NavbarProps {
    // navigation: NavigationItem[];
    isCollapsed: boolean;
    // onCollapse: (isCollapsed: boolean) => void;
}

export function Navbar({
    // navigation,
    className,
    isCollapsed,
}: // onCollapse,
ComponentProps<NavbarProps>) {
    const theme = useTheme();

    return (
        <Styles.Container className={className} isCollapsed={isCollapsed}>
            <>
                <Styles.Header>
                    <Tooltip
                        type='secondary'
                        position='right'
                        tooltipContent={`Test User Workspace`}
                    >
                        <img src={logoIconSrc} alt='Logo' />
                    </Tooltip>
                </Styles.Header>
                <Tooltip type='secondary' position='right' tooltipContent='Show More'>
                    <Styles.ExpandControl>
                        <Styles.Arrow icondirection={isCollapsed} />
                    </Styles.ExpandControl>
                </Tooltip>
                <Styles.Nav>
                    <Styles.LinksList>
                        <Styles.LinkListItem>
                            <Tooltip type='secondary' position='right' tooltipContent={'User'}>
                                <Styles.Link className={className} to={'sdas'} disabled={true}>
                                    <Styles.NavLinkIcon>
                                        <ProfileIcon />
                                    </Styles.NavLinkIcon>
                                </Styles.Link>
                            </Tooltip>
                        </Styles.LinkListItem>
                        <Styles.LinkListItem>
                            <Tooltip type='secondary' position='right' tooltipContent={'Dashboard'}>
                                <Styles.Link
                                    className={className + ' active'}
                                    to={'sdas'}
                                    disabled={true}
                                >
                                    <Styles.NavLinkIcon>
                                        <DashboardIcon />
                                    </Styles.NavLinkIcon>
                                </Styles.Link>
                            </Tooltip>
                        </Styles.LinkListItem>
                        <Styles.LinkListItem>
                            <Tooltip
                                type='secondary'
                                position='right'
                                tooltipContent={'Connections'}
                            >
                                <Styles.Link className={className} to={'sdas'} disabled={true}>
                                    <Styles.NavLinkIcon>
                                        <ConnectionsIcon />
                                    </Styles.NavLinkIcon>
                                </Styles.Link>
                            </Tooltip>
                        </Styles.LinkListItem>
                        <Styles.LinkListItem>
                            <Tooltip type='secondary' position='right' tooltipContent={'sadas'}>
                                <Styles.Link className={className} to={'sdas'} disabled={true}>
                                    <Styles.NavLinkIcon>
                                        <RectangleIcon />
                                    </Styles.NavLinkIcon>
                                </Styles.Link>
                            </Tooltip>
                        </Styles.LinkListItem>
                        {/* <Styles.LinkListItem>
                            
                        </Styles.LinkListItem> */}
                        <Styles.LinkListItem>
                            <Tooltip type='secondary' position='right' tooltipContent={'Settings'}>
                                <Styles.Link className={className} to={'sdas'} disabled={true}>
                                    <SettingIcon />
                                </Styles.Link>
                            </Tooltip>
                            <Tooltip type='secondary' position='right' tooltipContent={'Help'}>
                                <Styles.Link className={className} to={'sdas'} disabled={true}>
                                    <HelpIcon />
                                </Styles.Link>
                            </Tooltip>
                        </Styles.LinkListItem>
                    </Styles.LinksList>
                </Styles.Nav>
            </>
        </Styles.Container>
    );
}

function NavigationDropdown({
    label,
    Icon,
    subItems,
    isCollapsed,
}: NavbarDropdown & { isCollapsed?: boolean }) {
    const theme = useTheme();
    const [isOpened, setIsOpened] = useState(false);
    const subItemsHeight = getDropdownItemsHeight(subItems.length);

    return (
        <Styles.SubMenuContainer
            onMouseEnter={() => setIsOpened(true)}
            onMouseLeave={() => setIsOpened(false)}
        >
            <Styles.Link className='connections-navigation' as='div'>
                {isCollapsed ? (
                    <>
                        <Styles.NavLinkIcon as={Icon} />
                        <Styles.NavLinkLabel variant='body1' color={theme.palette.common.white}>
                            {label}
                        </Styles.NavLinkLabel>
                    </>
                ) : (
                    <>
                        {label ? (
                            <Styles.NavLinkIcon as={Icon} />
                        ) : (
                            <Tooltip type='secondary' position='right' tooltipContent={label}>
                                <Styles.NavLinkIcon as={Icon} />
                            </Tooltip>
                        )}
                    </>
                )}
            </Styles.Link>
            <Styles.NavSubItems subItemsHeight={subItemsHeight} subMenuState={isOpened}>
                <Styles.SubMenuHeader>{label}</Styles.SubMenuHeader>
                {subItems?.map((navItem) => (
                    <NavigationLink key={navItem.label} {...navItem} />
                ))}
            </Styles.NavSubItems>
        </Styles.SubMenuContainer>
    );
}

function NavigationLink({ label, url, Icon }: NavbarLink) {
    const disabled: boolean = false;
    const theme = useTheme();
    const className = (label + '-navigation').toLowerCase().replaceAll(' ', '');

    return (
        <Styles.Link className={className} to={url} disabled={disabled}>
            <Styles.NavLinkIcon as={Icon} />
            <Styles.NavLinkLabel variant='body1' color={theme.palette.common.white}>
                {label}
            </Styles.NavLinkLabel>
        </Styles.Link>
    );
}

function getDropdownItemsHeight(itemsCount: number) {
    return itemsCount * (Styles.linkHeight + Styles.linkVerticalOffset);
}

function getFirstNamePossessive(firstName: string) {
    return firstName.endsWith('s') ? `${firstName}'` : `${firstName}'s`;
}
