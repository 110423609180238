import styled from 'styled-components';
import { displayContentCenter, displayContentSpaceBetween } from '../../common/styles';
import { Divider } from '../../components/divider';
import { MultiselectDropdown } from '../../components/dropdown';
import { Loadable } from '../../components/loadable';
import { Typography } from '../../components/typography';
import { Tabs } from '../../components/tabs';

export const BaseTabs = styled(Tabs)`
    //width: 350px !important;

    button {
        &::before {
            // width: 70% !important;
        }
    }
`;

export const StyledLoadable = styled(Loadable)`
    & .blurable-circle-loader {
        top: 28%;
        left: 48%;
    }
`;

export const SectionHeader = styled.div`
    ${displayContentSpaceBetween}
    min-height: 30px;
    margin-bottom: 28px;
`;

export const SectionTitle = styled(Typography)`
    display: flex;
    align-items: center;
`;

export const StatisticContainer = styled.div`
    width: 100%;
`;

export const ChartContainer = styled.div`
    display: inline-flex;
    flex-grow: 1;
    width: 100%;
`;

export const GrowthStatisticContainer = styled.div`
    ${displayContentCenter};
    margin-bottom: 25px;
`;

export const GrowthDivider = styled.div`
    min-height: 22px;
    width: 1px;
    background-color: ${({ theme }) => theme.palette.common.black1};
`;

export const SectionHeaderRight = styled.div`
    display: flex;
    gap: 15px;
`;
export const Separator = styled(Divider)`
    margin-top: ${({ theme }) => theme.spacing.components.spacing1};
    margin-bottom: 25px;
`;

export const specialEventDropdown = styled(MultiselectDropdown)`
    max-width: 150px;
`;

export const SpecialInfoContainer = styled.div`
    display: flex;
    margin: 25px 0px;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 8px;

    &::before {
        content: '';
        display: block;
        width: 52px;
        height: 1px;
        background: #e4e9f3;
        left: 0;
        top: 50%;
    }
    &::after {
        content: '';
        display: block;
        width: 52px;
        height: 1px;
        background: #e4e9f3;
        right: 0;
        top: 50%;
    }
`;
export const SpecialInfoIcon = styled.div`
    ${displayContentCenter}
    padding: 0px 10px;
`;
export const SpecialInfoMessage = styled(Typography)`
    font-style: italic;
`;

export const BlockDivider = styled.div`
    border-bottom: 1px dashed #e4e9f3;
    margin: 25px 0px;
`;
