import styled from 'styled-components';

export interface DividerStyleProps {
    vertical?: boolean;
    borderStyle?: 'solid' | 'dashed';
    customColor?: boolean;
}

export const Divider = styled.div<DividerStyleProps>`
    width: ${({ vertical }) => (vertical ? '1px' : '100%')};
    height: ${({ vertical }) => (vertical ? '100%' : '1px')};
    border-bottom: ${({ vertical, borderStyle }) =>
        vertical ? '' : `1px ${borderStyle || 'solid'}`};
    border-right: ${({ vertical, borderStyle }) =>
        vertical ? `1px ${borderStyle || 'solid'}` : ''};
    border-color: ${({ theme }) => theme.palette.common.mercury};
`;
