import { RangeSliderTheme } from '../../../../models/theme';
import { roundRadius } from '../../../roundRadius';
import { shadows } from '../../../shadows';
import { palette } from '../palette';

export const rangeSliderTheme: RangeSliderTheme = {
    primary: {
        colors: {
            background: palette.common.downriver,
            trackBackground: palette.common.shakespeare,
            railBackground: palette.common.catskillWhite,
        },
        shadow: shadows.style8,
        roundRadius: roundRadius.radius7,
    },
};
