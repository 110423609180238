import { TooltipPlacement } from 'antd/lib/tooltip';
import { ComponentProps } from '../../common/types';
import * as Styles from './styles';
import { useEffect, useState } from 'react';
export interface TooltipProps {
    children?: JSX.Element | string;
    position?: TooltipPlacement;
    tooltipContent?: JSX.Element | string | null;
    type?: string;
    overlayClassName?: string;
}

export function Tooltip({
    overlayClassName,
    children,
    position,
    tooltipContent,
    type = 'primary',
}: ComponentProps<TooltipProps>) {
    useEffect(() => {
        
    }, [type,overlayClassName]);
    return (
        <Styles.TooltipContainer
            overlayClassName={overlayClassName}
            title={tooltipContent}
            placement={position}
        >
            <Styles.CustomizedTooltipStyle type={type} />
            {children}
        </Styles.TooltipContainer>
    );
}
