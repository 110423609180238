import styled from 'styled-components';
import { Typography } from '../../../../components/typography';
import { Dropdown } from '../../../../components/dropdown';
import { Button } from '../../../../components/button';

export const Container = styled.div`
    background: #ffffff;
    border-radius: 5px;
`;

export const Head = styled.div`
    display: flex;
    align-items: center;
    min-height: 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid #3c8de5;
    padding: 20px 15px;
    gap: 15px;
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #00b994;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
        background: #00b994;
    }
    .ant-steps-item-process.ant-steps-item-active
        .ant-steps-dot
        .ant-steps-item-icon
        .ant-steps-icon-dot::after {
        width: 12px;
        height: 12px;
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #4295cd;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
        background: #dbe2ef;
    }
    .ant-steps-item-wait .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after {
        top: -1px;
        left: -1px;
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        border-radius: 100px;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
        background-color: #00b994;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-color: #00b994;
    }
`;
export const Divider = styled.div`
    background: #e4e9f3;
    height: 20px;
    width: 2px;
`;
export const Stepper = styled.div`
    height: 20px;
    width: 78%;
    padding-top: 7px;
`;
export const Close = styled.div`
    display: flex;
    width: 2%;
    cursor: pointer;
`;

export const Title = styled(Typography)`
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    color: #444444;
    width: 20%;
`;

export const Text = styled(Typography)`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: #444444;
`;

export const Icon = styled(Typography)`
    display: flex;
    margin-right: 10px;
`;

export const Footer = styled.div`
    padding: 30px 0px;
    border-top: 1px solid #e4e9f3;
    margin: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

export const FooterBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

export const Content = styled.div`
    padding: 0px 15px;
`;

export const MenuDropdown = styled(Dropdown)`
    min-width: 170px !important;
`;

export const AddNew = styled(Button)`
    height: 30px;
    border-left: 2px solid #e5e5e5;
    padding: 0px 15px;
    min-width: auto;
    border-radius: 0px;
`;
