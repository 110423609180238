import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { ComponentProps } from '../../common/types';

import { lightTheme } from '../../common/styles/themes/light';
import { darkTheme } from '../../common/styles/themes/dark';
import { ThemeMode } from '../../common/models/theme';

const currentThemeMode: ThemeMode | null = localStorage.getItem(
    'theme-mode'
) as ThemeMode;

export const currentTheme =
    currentThemeMode === ThemeMode.dark ? darkTheme : lightTheme;

export function ThemeProvider({ children }: ComponentProps) {
    return <SCThemeProvider theme={currentTheme}>{children}</SCThemeProvider>;
}
