import styled from 'styled-components';
import { Typography as TypographyModel } from '../../common/models/theme';

export interface TypographyStylesProps {
    variant: keyof TypographyModel;
    color?: string;
}

export const Typography = styled.div<TypographyStylesProps>`
    font-family: 'Montserrat';
    color: ${({ color, theme }) => color || theme.palette.text.primary};
    font-size: ${({ theme, variant }) => theme.typography[variant].fontSize};
    font-weight: ${({ theme, variant }) =>
        theme.typography[variant].fontWeight};
    line-height: ${({ theme, variant }) =>
        theme.typography[variant].lineHeight};
    margin: 0;
`;
