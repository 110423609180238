import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const BreadCrumbContainer = styled.nav`
    display: flex;
    align-items: center;
    height: 40px;
    background: ${({ theme }) => theme.components.breadcrumb.primary.colors.background};
    box-shadow: ${({ theme }) => theme.components.breadcrumb.primary.shadow};
    padding: 10px 20px;
`;
export const BreadCrumb = styled.ol`
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: ${({ theme }) => theme.typography.label1.fontSize};
    font-weight: ${({ theme }) => theme.typography.label1.fontWeight};
    line-height: ${({ theme }) => theme.typography.label1.lineHeight};
`;
export const Link = styled(NavLink)`
    color: ${({ theme }) => theme.components.breadcrumb.primary.colors.link};
`;
export const NoLink = styled.span`
    color: ${({ theme }) => theme.components.breadcrumb.primary.colors.text};
`;
export const Separator = styled.span`
    padding: 0px 7px;
    color: ${({ theme }) => theme.components.breadcrumb.primary.colors.separator};
`;
