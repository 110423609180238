import React, { useState } from 'react';
import * as Styles from './styles';
import { ReactComponent as UploadIcon } from './icons/upload.svg';
import { RadioButton } from '../../../../components/radio';
import { FormControl } from '../../../../components/form-control';
import { Input } from '../../../../components/input';
import { ComponentProps } from '../../../../common/types';
import { Upload } from 'antd';
import { InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

const { Dragger } = Upload;

export interface CampaignAdProps {
    imageUrl: string;
    setImageUrl: any;
}
export function CampaignCreateAd({
    imageUrl,
    setImageUrl
}: ComponentProps<CampaignAdProps>) {
    const [loading, setLoading] = useState(false);
    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
      };
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj as RcFile, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
      };
    const uploadButton = (
        <div className='ant-upload ant-upload-drag'>
            {loading ? <LoadingOutlined /> : <UploadIcon />}
            <p className="ant-upload-text">Drag & Drop Ad file here</p>
            <p className="ant-upload-hint">
            - or -
            </p>
            <p className="ant-upload-hint">
            Browse File
            </p>
        </div>
      );
    return (
        <Styles.Container>
            <Styles.HeaderContainer>
                <div>
                    <Styles.TopTitle>Create Ad</Styles.TopTitle>
                    <Styles.SubTitle>Select the campaign objective that is the best fit for your business goal</Styles.SubTitle>
                </div>
                
            </Styles.HeaderContainer>
            <Styles.Divider />
            <Styles.SelectContainer>
                    <Styles.LeftContainer>
                        <Styles.RadioContainer>
                        <Styles.InputConatiner>
                            <div>Ad Name</div>
                            <input value='15% off Melts - Poster' />
                        </Styles.InputConatiner>
                        <Styles.Select>
                            <Styles.Title>Ad Creative</Styles.Title>
                            <Styles.SubTitle>
                                Select the media, photos, or content you would like to appear in your campaign
                            </Styles.SubTitle>
                            <Styles.Title>Media and Content</Styles.Title>
                            <Styles.SubTitle>
                            Your Single image or video ad requires at least 1 image or video. Please select media in the Ad Creative section
                            </Styles.SubTitle>
                            <Styles.SelectContainer>
                                <Styles.InputConatiner>
                                    <div>Primary Text</div>
                                    <input value='15% off Melts - Poster' />
                                </Styles.InputConatiner>
                                <Styles.InputConatiner>
                                    <div>Headlines</div>
                                    <input value='15% off Melts - Poster' />
                                </Styles.InputConatiner>
                            </Styles.SelectContainer>
                            <Styles.InputFullConatiner>
                            <div>Description</div>
                            <input placeholder='Enter description (0/225)' />
                            </Styles.InputFullConatiner>
                            <Styles.InputConatiner>
                            <div>Display Line</div>
                            <input value='Shortened Link' />
                            </Styles.InputConatiner>
                        </Styles.Select>
                        </Styles.RadioContainer>
                    </Styles.LeftContainer>
                    <Styles.UploadContainer>
                    <Styles.Title>Ad Preview</Styles.Title>
                    <Styles.SubTitle>
                        Preview ad creative based on selected placements
                    </Styles.SubTitle>
                    <Styles.UploadBox>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        onChange={handleChange}
                    >
                        {imageUrl ? <Styles.ImageView><img src={imageUrl} alt="avatar" /></Styles.ImageView> : uploadButton}
                    </Upload>
                    {/*  <Dragger >
                        <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Drag & Drop Ad file here</p>
                        <p className="ant-upload-hint">
                        - or -
                        </p>
                        <p className="ant-upload-hint">
                        Browse File
                        </p>
                    </Dragger> */}
                    </Styles.UploadBox>
                    </Styles.UploadContainer>
            </Styles.SelectContainer>
        </Styles.Container>
    );
}
