import styled from 'styled-components';
import { Typography } from '../../../../components/typography';
import { Dropdown } from '../../../../components/dropdown';
import { Button } from '../../../../components/button';

export const Head = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 30px;
    margin-bottom: 30px;
`;

export const HeadLeft = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HeadRight = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

export const Title = styled(Typography)`
    display: flex;
    align-items: center;
`;

export const SubTitle = styled(Typography)`
    margin-top: 5px;
`;

export const MenuDropdown = styled(Dropdown)`
    min-width: 170px !important;
`;

export const AddNew = styled(Button)`
    height: 30px;
    border-left: 2px solid #e5e5e5;
    padding: 0px 15px;
    min-width: auto;
    border-radius: 0px;
    &:hover {
        border-left: 2px solid #e5e5e5;
    }
`;
