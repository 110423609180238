import styled from 'styled-components';
import { layout } from '../../common/styles';

export const PageLayout = styled.div`
    background: ${({ theme }) =>
        theme.components.pageLayout.primary.colors.background};
    padding-left: ${({ theme }) => theme.spacing.components.spacing1};
    padding-right: ${({ theme }) => theme.spacing.components.spacing1};
    padding-top: ${({ theme }) => theme.spacing.components.spacing3};
    padding-bottom: ${({ theme }) => theme.spacing.components.spacing3};
    min-height: calc(100vh - ${layout.headerHeight});
`;
