import { PageLayoutTheme } from '../../../../models/theme';
import { palette } from '../palette';

export const pageLayoutTheme: PageLayoutTheme = {
    primary: {
        colors: {
            background: palette.common.aliceBlueSub2,
        },
    },
};
