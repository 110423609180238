import styled from 'styled-components';
import { layout } from '../../common/styles';
import { displayContentCenter, displayContentSpaceBetween } from '../../common/styles';

export const Container = styled.header<{ HeaderWidth: boolean }>`
    position: fixed;
    top: 0;
    z-index: 99;
    ${displayContentSpaceBetween}
    height: ${layout.headerHeight};
    width: ${(props) =>
        props.HeaderWidth ? `calc(100% - ${layout.navbarWidth})` : 'calc(100% - 50px)'};
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.components.header.primary.colors.background};
`;

export const ActionContainer = styled.div`
    ${displayContentCenter}
`;

export const ProfileIcon = styled.div<{ active?: boolean }>`
    ${displayContentCenter}
    width: 40px;
    height: 40px;
    border-radius: ${({ theme }) => theme.roundRadius.radius4};
    background-color: ${({ theme }) => theme.palette.common.botticelli};
    cursor: ${({ active }) => (active ? 'pointer' : 'default')};
`;

export const IconContainer = styled.div`
    position: relative;
    padding: 0px 15px;
    &::after {
        content: '';
        width: 0.5px;
        height: 55%;
        right: 0;
        top: 20%;
        background: ${({ theme }) => theme.palette.common.catskillWhite};
        position: absolute;
    }
    &:last-child {
        padding-right: 0px;
        &::after {
            display: none;
        }
    }
`;
