import styled, { keyframes } from 'styled-components';
import { colors } from '../../common/styles/colors';

export interface CircleLoaderProps {
    size?: string;
    color?: string;
    marginTop?: string;
}

const loadAnimation = keyframes`
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
        clip-path: polygon(
            50% 50%,
            0 0,
            100% 0,
            100% 100%,
            100% 100%,
            100% 100%
        );
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
`;

export const CircleLoader = styled.div.attrs<CircleLoaderProps>(
    ({ size, color, marginTop }) => ({
        size: size || '50px',
        color: color || colors.blue,
        marginTop: marginTop || 0,
        className: 'circle-loader'
    })
)<CircleLoaderProps>`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    margin-top: ${(props) => props.marginTop};

    &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        inset: 0px;
        border-radius: 50%;
        border: 4px solid ${({ color }) => color};
        animation: ${loadAnimation} 2s linear infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
`;
