import { Theme } from '../../../models/theme';
import { palette } from './palette';
import { typography } from '../../typography';
import { roundRadius } from '../../roundRadius';
import { spacing } from '../../spacing';
import { shadows } from '../../shadows';
import { transitions } from '../../transitions';
import { components } from './components';
import { ThemeMode } from '../../../../common/models/theme';

export const lightTheme: Theme = {
    mode: ThemeMode.light,
    palette,
    components,
    typography,
    roundRadius,
    spacing,
    shadows,
    transitions,
};
