import * as Styles from './styles';
import { Select, Space, Table, Tag } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { ReactComponent as UpArrow } from './icons/up.svg';
import { ReactComponent as DownArrow } from './icons/down.svg';
import { Button } from '../../../../components/button';

interface DataType {
    date: string;
    name: string;
    platform: string;
    myFootTraffic: string;
    markerFootTraffic: string;
}

const columns: ColumnsType<DataType> = [
    {
        dataIndex: 'date',
        key: 'date',
        title: (titleProps) => {
            const sortedColumn = titleProps.sortColumns?.find(
                ({ column }) => column.key === 'date'
            );
            return (
                <Styles.ArrowContainer>
                    Date
                    {sortedColumn?.order === 'ascend' ? (
                        <Styles.ArrowDownIcon />
                    ) : (
                        <Styles.ArrowUpIcon />
                    )}
                </Styles.ArrowContainer>
            );
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a.date.length - b.date.length,
    },
    {
        dataIndex: 'name',
        key: 'name',
        title: (titleProps) => {
            const sortedColumn = titleProps.sortColumns?.find(
                ({ column }) => column.key === 'name'
            );
            return (
                <Styles.ArrowContainer>
                    Name
                    {sortedColumn?.order === 'ascend' ? (
                        <Styles.ArrowDownIcon />
                    ) : (
                        <Styles.ArrowUpIcon />
                    )}
                </Styles.ArrowContainer>
            );
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a.name.length - b.name.length,
    },
    {
        dataIndex: 'platform',
        key: 'platform',
        title: (titleProps) => {
            const sortedColumn = titleProps.sortColumns?.find(
                ({ column }) => column.key === 'platform'
            );
            return (
                <Styles.ArrowContainer>
                    Platform
                    {sortedColumn?.order === 'ascend' ? (
                        <Styles.ArrowDownIcon />
                    ) : (
                        <Styles.ArrowUpIcon />
                    )}
                </Styles.ArrowContainer>
            );
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a.platform.length - b.platform.length,
    },

    {
        key: 'myFootTraffic',
        dataIndex: 'myFootTraffic',
        title: (titleProps) => {
            const sortedColumn = titleProps.sortColumns?.find(
                ({ column }) => column.key === 'myFootTraffic'
            );
            return (
                <Styles.ArrowContainer>
                    My Foot Traffic
                    {sortedColumn?.order === 'ascend' ? (
                        <Styles.ArrowDownIcon />
                    ) : (
                        <Styles.ArrowUpIcon />
                    )}
                </Styles.ArrowContainer>
            );
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a.myFootTraffic.length - b.myFootTraffic.length,
        render: (text) => (
            <Styles.Value>
                {text.includes('+') ? (
                    <>
                        <UpArrow />
                        {text}%
                    </>
                ) : (
                    <>
                        <DownArrow />
                        {text}%
                    </>
                )}
            </Styles.Value>
        ),
    },
    {
        dataIndex: 'markerFootTraffic',
        key: 'markerFootTraffic',
        width: '23%',
        title: (titleProps) => {
            const sortedColumn = titleProps.sortColumns?.find(
                ({ column }) => column.key === 'markerFootTraffic'
            );
            return (
                <Styles.ArrowContainer>
                    Market Foot Traffic
                    {sortedColumn?.order === 'ascend' ? (
                        <Styles.ArrowDownIcon />
                    ) : (
                        <Styles.ArrowUpIcon />
                    )}
                </Styles.ArrowContainer>
            );
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
        sorter: (a, b) => a.markerFootTraffic.length - b.markerFootTraffic.length,
        render: (text) => (
            <Styles.Value>
                {text.includes('+') ? (
                    <>
                        <UpArrow />
                        {text}%
                    </>
                ) : (
                    <>
                        <DownArrow />
                        {text}%
                    </>
                )}
            </Styles.Value>
        ),
    },
];

const data: DataType[] = [
    {
        date: '09 August 2022',
        name: 'New Pancake Meal',
        platform: 'Instagram',
        myFootTraffic: '+ 15',
        markerFootTraffic: '25',
    },
    {
        date: '20 August 2022',
        name: 'New Chicken Melt',
        platform: 'Facebook',
        myFootTraffic: '- 03',
        markerFootTraffic: '- 01',
    },
    {
        date: '10 August 2022',
        name: 'Coffee with Pancakes',
        platform: 'Instagram',
        myFootTraffic: '+ 18',
        markerFootTraffic: '- 05',
    },
    {
        date: '25 August 2022',
        name: 'Hot Chocolate',
        platform: 'Mailchimp',
        myFootTraffic: '- 02',
        markerFootTraffic: '+ 12',
    },
    {
        date: '03 August 2022',
        name: 'Family Deal',
        platform: 'Facebook',
        myFootTraffic: '- 05',
        markerFootTraffic: '+ 15',
    },
    {
        date: '01 May 2022',
        name: 'New Sasuage Promo',
        platform: 'Google Ads',
        myFootTraffic: '- 08',
        markerFootTraffic: '+ 11',
    },
];

export function RecentCampaigns() {
    const handleUnderChange = (value: string) => {};

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        // console.log("params", pagination, filters, sorter, extra);
    };

    return (
        <Styles.Container>
            <Styles.Head>
                <Styles.Title>Recent Campaigns</Styles.Title>
                <Styles.View>view all</Styles.View>
            </Styles.Head>
            <Table
                tableLayout='fixed'
                rowKey='date'
                columns={columns}
                dataSource={data}
                pagination={false}
                className='process'
                onChange={onChange}
                showSorterTooltip={false}
            />
        </Styles.Container>
    );
}
