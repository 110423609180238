import { InputHTMLAttributes, useContext } from 'react';
import { ComponentProps, ColorState } from '../../common/types';
import { FormControlContext, FormControlContextValue } from '../form-control';
import * as Styles from './styles';

export interface InputProps extends Styles.InputStyleProps, InputHTMLAttributes<HTMLInputElement> {
    icon?: JSX.Element;
    loading?: boolean;
    error?: boolean;
    success?: boolean;
    warning?: boolean;
    custom?:boolean;
}

export function Input({
    className,
    icon,
    success,
    warning,
    error,
    loading,
    custom,
    iconPosition = 'right',
    variant = 'primary',
    required,
    disabled,
    ...restProps
}: ComponentProps<InputProps>) {
    const formControlContext = useContext(FormControlContext);
    const showIcon = !loading || iconPosition === 'left';
    let stateProps: FormControlContextValue = {
        required,
        disabled,
        colorState: 'main',
    };
    if (formControlContext) {
        stateProps = {
            ...formControlContext,
            ...stateProps,
        };
    } else {
        stateProps.colorState = ((error && 'error') ||
            (success && 'success') ||
            (warning && 'warning') ||
            (custom && 'custom') ||
            'main') as ColorState;
    }

    return (
        <Styles.RelativeInputContainer className={className}>
            <Styles.Input
                className={className}
                iconPosition={iconPosition}
                loading={loading}
                variant={variant}
                colorVariant={stateProps.colorState}
                required={stateProps.required}
                disabled={stateProps.disabled}
                {...restProps}
            ></Styles.Input>
            {showIcon && (
                <Styles.InputIcon iconPosition={iconPosition} variant={variant}>
                    {icon}
                </Styles.InputIcon>
            )}
            {loading && <Styles.InputLoader />}
        </Styles.RelativeInputContainer>
    );
}
