export function isChildElement(child: HTMLElement, parent: HTMLElement) {
    let currentNode: HTMLElement | null = child;
    let isChild = false;
    while (currentNode) {
        if (currentNode === parent) {
            isChild = true;
            break;
        }
        currentNode = currentNode.parentElement;
    }

    return isChild;
}
