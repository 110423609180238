import styled, { css } from 'styled-components';
import { colors, absoluteCenteredVertical } from '../../common/styles';
import { Theme } from '../../common/models/theme';
import { ReactComponent as BaseSelectedIcon } from '../../assets/icons/selected-icon.svg';
import {
    RadioButtonThemeColorVariants,
    RadioButtonTheme,
} from '../../common/models/theme/components/RadioButtonTheme';
import { Typography as BaseTypography } from '../../components/typography';

export const RadioInput = styled.input`
    display: none;
`;

export const RadioButtonLabel = styled.label`
    position: relative;
    display: block;
    padding-left: 35px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: #444444;

    &::before {
        ${absoluteCenteredVertical};
        content: '';
        left: 0;
        width: 20px;
        height: 20px;
        border: 2px solid ${colors.blue};
        border-radius: 50%;
        background-color: #fff;
    }
    input:checked + & {
        color: #4295cd;
    }

    input:checked + &::after {
        ${absoluteCenteredVertical};
        content: '';
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${colors.blue};
    }
`;

export const RadioButtonCardIcon = styled.div`
    ${absoluteCenteredVertical};
    left: 30px;
`;

export const SelectedIcon = styled(BaseSelectedIcon)`
    display: none;
    position: absolute;
    top: 6px;
    right: 6px;
    fill: ${({ theme }) => theme.palette.primary.main};
`;

export const Typography = styled(BaseTypography)``;

export const RadioButtonCardLabel = styled.label<{
    showIcon: boolean;
}>`
    position: relative;
    display: flex;
    align-items: center;
    min-width: 180px;
    min-height: 60px;
    justify-content: center;
    padding: ${({ showIcon }) => (showIcon ? '19px 45px 19px 100px' : '19px 45px')};
    cursor: pointer;
    font-size: 18px;
    border: 2px solid;
    border-radius: ${({ theme }) => theme.components.radioButton.primary.roundRadius};
    ${({ theme }) => getStylesByVariantAndState(theme, 'primary', 'normal')}

    input:checked + & {
        ${({ theme }) => getStylesByVariantAndState(theme, 'primary', 'selected')}
        ${SelectedIcon} {
            display: block;
        }
    }

    &:hover {
        ${({ theme }) => getStylesByVariantAndState(theme, 'primary', 'hover')}
    }

    &:disabled {
        ${({ theme }) => getStylesByVariantAndState(theme, 'primary', 'disabled')}
    }
`;

function getStylesByVariantAndState(
    theme: Theme,
    inputVariant: keyof RadioButtonTheme,
    state: keyof RadioButtonThemeColorVariants
) {
    return css`
        border-color: ${theme.components.radioButton[inputVariant].colors[state].border};
        background-color: ${theme.components.radioButton[inputVariant].colors[state].background};

        & ${Typography} {
            color: ${theme.components.radioButton[inputVariant].colors[state].text};
        }

        ${RadioButtonCardIcon}, ${SelectedIcon} {
            fill: ${theme.components.radioButton[inputVariant].colors[state].icon};
        }
    `;
}
