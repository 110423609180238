import { InputHTMLAttributes } from 'react';
import { ComponentProps } from '../../common/types';
import * as Styles from './styles';

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    label?: string;
}

export function Checkbox({
    children,
    className,
    label,
    ...restProps
}: ComponentProps<CheckboxProps>) {
    return (
        <div className={className}>
            <Styles.CheckboxInput type='checkbox' {...restProps} />
            <Styles.CheckboxLabel htmlFor={restProps.id}>
                <Styles.TickIcon />
                {label}
            </Styles.CheckboxLabel>
        </div>
    );
}
