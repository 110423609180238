import { DatatableTheme } from '../../../../models/theme/components';
import { palette } from '../palette';

export const datatableTheme: DatatableTheme = {
    primary: {
        colors: {
            dataTable: {
                text: palette.common.doveGray,
                background: palette.background.block,
                border: palette.background.block,
            },
            dataHeadingRow: {
                text: palette.primary.main,
                background: palette.common.solitude,
                border: palette.common.solitude,
            },
            dataRow: {
                text: palette.common.doveGray,
                background: palette.background.block,
                border: palette.background.block,
            },
            dataSelectedRow :
            {
                text: palette.common.doveGray,
                background: palette.common.aliceBlueSub1,
                border: palette.common.aliceBlueSub1,
            },
            dataAlternateRow: {
                text: palette.common.doveGray,
                background: palette.common.lightShadeGray,
                border: palette.common.lightShadeGray,
            },
            dataHightLIghtRow: {
                text: palette.common.doveGray,
                background: palette.common.aliceBlueSub1,
                border: palette.primary.light,
            },
        },
    },
};
