import { RangeSliderTheme } from '../../../../models/theme';
import { roundRadius } from '../../../roundRadius';
import { shadows } from '../../../shadows';
import { palette } from '../palette';

export const rangeSliderTheme: RangeSliderTheme = {
    primary: {
        colors: {
            background: palette.common.alabaster,
            trackBackground: palette.common.alabaster,
            railBackground: palette.common.alabaster,
        },
        shadow: shadows.style1,
        roundRadius: roundRadius.radius1,
    },
};
