import { useState, useEffect, useCallback, MutableRefObject } from 'react';
import { isChildElement } from '../../../common/utils';

export interface UseDropdownPayload {
    isOpened: boolean;
    handleDropButtonClick: () => void;
}

export function useDropdown(
    containerRef: MutableRefObject<HTMLDivElement | null> | null
): UseDropdownPayload {
    const [isOpened, setIsOpened] = useState(false);

    const handleDropButtonClick = () => {
        setIsOpened((prevIsOpened) => !prevIsOpened);
    };

    const windowClickHandler = useCallback((e: MouseEvent) => {
        const isClickOutside = !isChildElement(
            e.target as HTMLElement,
            containerRef?.current as HTMLElement
        );

        if (isClickOutside) {
            setIsOpened(false);
        }
    }, []);

    useEffect(() => {
        if (isOpened) {
            window.addEventListener('click', windowClickHandler);
        } else {
            window.removeEventListener('click', windowClickHandler);
        }

        return () => window.removeEventListener('click', windowClickHandler);
    }, [isOpened]);

    useEffect(() => {
        const zIndexToChange = isOpened ? '10' : '1';
        (containerRef?.current as HTMLDivElement).style.zIndex = zIndexToChange;
    }, [isOpened]);

    return {
        isOpened,
        handleDropButtonClick,
    };
}
