import { TooltipTheme } from '../../../../models/theme/components';
import { roundRadius } from '../../../roundRadius';
import { shadows } from '../../../shadows';
import { palette } from '../palette';

export const tooltipTheme: TooltipTheme = {
    primary: {
        text: palette.common.tundora,
        border: palette.common.downriver,
        background: palette.common.athensGrayLight,
        shadow: shadows.none,
        roundRadius: roundRadius.radius1,
    },
    secondary: {
        text: palette.common.tundora,
        border: palette.common.solitude1,
        background: palette.common.solitude1,
        shadow: shadows.style9,
        roundRadius: roundRadius.radius1,
    },
    custom: {
        text: palette.common.tundora,
        border: palette.common.white,
        background: palette.common.white,
        shadow: shadows.style9,
        roundRadius: roundRadius.radius1,
    },
};
