import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const Label = styled.label<{ withLabelMargin: boolean }>`
    display: flex;
    margin-bottom: ${({ withLabelMargin }) => (withLabelMargin ? '10px' : 0)};
    margin-top: 10px;
`;

export const MessagesContainer = styled.div``;

export const Message = styled.div`
    margin-top: 4px;
    font-size: ${({ theme }) => theme.typography.label1.fontSize};
    font-weight: ${({ theme }) => theme.typography.label1.fontWeight};
    line-height: ${({ theme }) => theme.typography.label1.lineHeight};
    color: ${({ color }) => color};
`;

export const Asterisk = styled.span`
    color: ${({ theme }) => theme.palette.error.main};
    line-height: ${({ theme }) => theme.typography.label1.lineHeight};
`;
