import { HeaderTheme } from '../../../../models/theme';
import { palette } from '../palette';

export const headerTheme: HeaderTheme = {
    primary: {
        colors: {
            background: palette.common.alabaster,
            text: palette.common.mineShaft,
        },
    },
};
