import styled, { css } from 'styled-components';
import { absoluteCenteredVertical } from '../../common/styles/mixins';
import { ReactComponent as BaseTickIcon } from '../../assets/icons/tick.svg';
import { Theme } from '../../common/models/theme';
import {
    CheckboxTheme,
    CheckboxThemeStates,
} from '../../common/models/theme/components/CheckboxTheme';

export const TickIcon = styled(BaseTickIcon)`
    ${absoluteCenteredVertical};
    left: 3px;
`;

export const CheckboxLabel = styled.label`
    position: relative;
    padding-left: 28px;
    font-size: ${({ theme }) => theme.typography.label2.fontSize};
    font-weight: ${({ theme }) => theme.typography.label2.fontWeight};
    line-height: ${({ theme }) => theme.typography.label2.lineHeight};
    cursor: pointer;
    ${({ theme }) => getColors(theme, 'primary', 'normal')}

    &::before {
        ${absoluteCenteredVertical};
        content: '';
        left: 0;
        width: 18px;
        height: 18px;
        border-width: 2px;
        border-style: solid;
        border-radius: ${({ theme }) => theme.components.checkbox.primary.roundRadius};
    }

    ${TickIcon} {
        display: none;
        fill: ${({ theme }) => theme.components.checkbox.primary.iconColor};
    }

    input:checked + & {
        ${({ theme }) => getColors(theme, 'primary', 'checked')};

        ${TickIcon} {
            display: block;
        }
    }

    input:disabled + & {
        opacity: 0.5;
        cursor: default;
    }
`;

export const CheckboxInput = styled.input`
    display: none;
`;

function getColors(
    theme: Theme,
    variant: keyof CheckboxTheme,
    checkboxState: keyof CheckboxThemeStates
) {
    return css`
        color: ${theme.components.checkbox[variant].colors[checkboxState].label};

        &::before {
            border-color: ${theme.components.checkbox[variant].colors[checkboxState].border};
            background-color: ${theme.components.checkbox[variant].colors[checkboxState]
                .background};
        }
    `;
}
