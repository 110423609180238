import styled from 'styled-components';
import { buttonReset } from '../../common/styles/mixins';
import { ReactComponent as BaseInfoIcon } from '../../assets/icons/info.svg';

export const TabNavigation = styled.div`
    position: relative;
    display: flex;
    padding: 5px 20px;
    background-color: ${({ theme }) => theme.palette.common.white};
    box-shadow: ${({ theme }) => theme.shadows.style8};
    width: 100%;
`;

export const TabNavigationItem = styled.button<{
    active?: boolean;
    disabled?: boolean;
}>`
    ${buttonReset};
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    font-size: ${({ theme }) => theme.typography.tabText.fontSize};
    font-weight: ${({ theme }) => theme.typography.tabText.fontWeight};
    line-height: ${({ theme }) => theme.typography.tabText.lineHeight};
    color: ${({ active, disabled, theme }) =>
        active && !disabled ? theme.palette.common.mineShaft : theme.palette.common.gray};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &::before {
        content: ${({ active, disabled }) => (active && !disabled ? "''" : 'none')};
        position: absolute;
        bottom: 0;
        left: 0;
        // width: 100%;
        width: 95%;
        height: 4px;
        background-color: ${({ theme }) => theme.palette.common.shakespeare};
    }
    div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export const InfoIcon = styled(BaseInfoIcon)`
    flex-shrink: 0;
    margin: 1px 5px;
`;
