import { ElementType } from 'react';
import { ComponentProps } from '../../../../common/types';
import * as Styles from './styles';
import { colors } from '../../../../common/styles';
import { ReactComponent as ReturnIcon } from './icons/return.svg';
import { ReactComponent as ImpressIcon } from './icons/impress.svg';
import { ReactComponent as ReachIcon } from './icons/reach.svg';
import { ReactComponent as AmountIcon } from './icons/amount.svg';

interface SummaryItem {
    id?: string;
    label: string;
    value: string | number;
    Icon: ElementType;
}

export function KpiTiles({ className }: ComponentProps) {
    const summaryItems: SummaryItem[] = [
        {
            id: 'amount',
            label: 'Amount Spent',
            value: '$2,889.78',
            Icon: AmountIcon,
        },
        {
            id: 'return',
            label: 'Return on Ad Spend',
            value: '3.6x',
            Icon: ReturnIcon,
        },
        {
            id: 'impress',
            label: 'Impressions',
            value: '45,678',
            Icon: ImpressIcon,
        },
        {
            id: 'reach',
            label: 'Reach',
            value: '67,456',
            Icon: ReachIcon,
        },
    ];

    return (
        <>
            <Styles.Container className={className}>
                {summaryItems.map(({ label, value, Icon, id }) => (
                    <Styles.SummaryItem id={id} key={label}>
                        <Styles.SummaryItemLabel tag='div'>{label}</Styles.SummaryItemLabel>
                        <Styles.SummaryItemValue className='summary-value'>
                            {value}
                        </Styles.SummaryItemValue>
                        <Styles.SummaryItemIcon as={Icon} />
                    </Styles.SummaryItem>
                ))}
            </Styles.Container>
        </>
    );
}
