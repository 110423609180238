import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from '../../components/navbar';
import { Header } from '../../components/header';
import { ThemeProvider } from '../theme/ThemeProvider';
import * as Styles from './styles';
import { Breadcrumb } from '../../components/breadcrumb';
import CampaignManagement from '../../pages/campaign-management/CampaignManagement';
import Dashboard from '../../pages/dashboard/Dashboard';

export default function RootWrapper() {
    return (
        <Router>
            <ThemeProvider>
                <Root />
            </ThemeProvider>
        </Router>
    );
}

function Root() {
    const Wrapper = true ? Styles.ShellWrapper : Styles.OnboardingWrapper;

    return (
        <Wrapper>
            <Navbar isCollapsed={false} />
            <Styles.ContentContainer isCollapsed={false}>
                {/* <Header />
                <Breadcrumb /> */}
                {/* <CampaignManagement /> */}
                <Routes>
                    <Route path='/campaign-management' element={<CampaignManagement />} />
                    <Route path='/' element={<Dashboard />} />
                    <Route path='*' element={<Dashboard />} />
                </Routes>
            </Styles.ContentContainer>
        </Wrapper>
    );
}
