import styled from 'styled-components';
import { ButtonHTMLAttributes } from 'react';
import { buttonReset } from '../../common/styles/mixins';

export const IconButton = styled.button<
    ButtonHTMLAttributes<HTMLButtonElement>
>`
    ${buttonReset};
    align-items: center;
    justify-content: center;
    display: inline-flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.2s linear;

    &:hover {
        background-color: ${({ disabled }) =>
            disabled ? 'none' : 'rgba(0, 0, 0, 0.08)'};
    }

    &:active {
        background-color: ${({ disabled }) =>
            disabled ? 'none' : 'rgba(0, 0, 0, 0.16)'};
    }
`;
