import styled, { css, createGlobalStyle } from 'styled-components';
import { Theme } from '../../common/models/theme';
import { TooltipTheme } from '../../common/models/theme/components/TooltipTheme';
import { Tooltip } from 'antd';

const primaryTooltipStyle = css`
    ${({ theme }) => getStylesByVariant(theme, 'primary')}
`;
const secondaryTooltipStyle = css`
    ${({ theme }) => getStylesByVariant(theme, 'secondary')}
`;
const customTooltipStyle = css`
    ${({ theme }) => getStylesByVariant(theme, 'custom')}
`;

export const CustomizedTooltipStyle = createGlobalStyle<{
    type?: string;
}>`

    .ant-tooltip-inner {
        ${({ type }) => (type === 'primary' ? primaryTooltipStyle : (type === 'custom' ? customTooltipStyle:secondaryTooltipStyle))};
        border:2px solid;
        font-weight: ${({ theme }) => theme.typography.label1.fontWeight};
        font-size: ${({ theme }) => theme.typography.label1.fontSize};
        line-height: ${({ theme }) => theme.typography.label1.lineHeight};
        padding:${({ theme }) => theme.spacing.form.spacing3};
    }
    .ant-tooltip-arrow{
        display:  ${({ type }) => (type === 'primary' ? 'block' : 'none')};
    }
    .ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight{
        padding-bottom:0px !important;
    }
    .ant-tooltip-placement-bottom, .ant-tooltip-placement-bottomLeft, .ant-tooltip-placement-bottomRight{
        padding-top:0px !important; 
    }
    .ant-tooltip-placement-left, .ant-tooltip-placement-leftTop, .ant-tooltip-placement-leftBottom{
        padding-right: 0px !important;
    }
    .ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom{
        padding-left: 0px !important;
    }
    .ant-tooltip-placement-topRight .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow{
        right:0px !important;
    }
    .ant-tooltip-placement-topLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow{
        left:0px !important;
    }
    .ant-tooltip.menu-item-info{
        min-width:200px !important;
        max-width:500px !important;
    }

`;

export const TooltipContainer = styled(Tooltip)``;

function getStylesByVariant(theme: Theme, tooltipVariant: keyof TooltipTheme) {
    return css`
        color: ${theme.components.tooltip[tooltipVariant].text} !important;
        border-color: ${theme.components.tooltip[tooltipVariant].border} !important;
        background-color: ${theme.components.tooltip[tooltipVariant].background} !important;
        box-shadow: ${theme.components.tooltip[tooltipVariant].shadow} !important;
        border-radius: ${theme.components.tooltip[tooltipVariant].roundRadius} !important;
    `;
}
