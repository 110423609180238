import styled from 'styled-components';
import { ReactComponent as Arrow } from './icons/arrow.svg';

export const Container = styled.div`
    .process {
        thead th {
            background: #f1f3f9;
            font-weight: 600;
            font-size: 14px;
            color: #4295cd;
            border-bottom: 0px;
            height: 40px;
            padding: 0px 15px;
            &::before {
                width: 0px !important;
            }
        }

        tbody td {
            height: 45px;
            font-weight: 600;
            font-size: 14px;
            color: #666666;
            border-bottom: 0px;
        }
        tr:nth-child(even) {
            background: #fafafa;
        }
    }
`;

export const ArrowContainer = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-left: 8px;
    }
`;

export const ArrowDownIcon = styled(Arrow)`
    fill: #08213c;
`;
export const ArrowUpIcon = styled(Arrow)`
    fill: #888888;
    transform: rotate(180deg);
`;

export const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: #222222;
`;

export const View = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: #4295cd;
    cursor: pointer;
`;

export const Value = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
