import styled from 'styled-components';
import { layout } from '../../common/styles';

export const OnboardingWrapper = styled.div`
    min-height: 100vh;
    min-width: 100vh;
`;

export const ShellWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export const ContentContainer = styled.div<{ isCollapsed: boolean }>`
    width: 100%;
    padding-left: ${(props) =>
        props.isCollapsed ? `${layout.navbarWidth}` : '50px'};
    padding-top: ${layout.headerHeight};
`;
