import { RadioButtonTheme } from '../../../../models/theme/components';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';
import { transitions } from '../../../transitions';

export const radioButtonTheme: RadioButtonTheme = {
    primary: {
        colors: {
            normal: {
                text: palette.primary.main,
                background: 'transparent',
                border: palette.primary.main,
                icon: palette.primary.main,
            },
            hover: {
                text: palette.primary.main,
                background: 'transparent',
                border: palette.primary.main,
                icon: palette.primary.main,
            },
            selected: {
                text: palette.primary.main,
                background: palette.common.linkWater,
                border: palette.primary.main,
                icon: palette.primary.main,
            },
            disabled: {
                text: palette.text.disabled,
                background: 'transparent',
                border: palette.action.disabled,
                icon: palette.action.disabled,
            },
        },
        roundRadius: roundRadius.radius1,
        borderTransition: transitions.borderColorTransition,
    },
};
