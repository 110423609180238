import { ElementType, InputHTMLAttributes } from 'react';
import { ComponentProps } from '../../common/types';
import * as Styles from './styles';

export interface RadioButtonProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
    label: string;
}

export interface RadioButtonCardProps extends RadioButtonProps {
    Icon?: ElementType;
}

export function RadioButton({
    children,
    className,
    label,
    ...restProps
}: ComponentProps<RadioButtonProps>) {
    return (
        <div className={className}>
            <Styles.RadioInput type='radio' {...restProps} />
            <Styles.RadioButtonLabel htmlFor={restProps.id}>
                {label}
            </Styles.RadioButtonLabel>
        </div>
    );
}

export function RadioButtonCard({
    children,
    className,
    label,
    Icon,
    ...restProps
}: RadioButtonCardProps) {
    return (
        <div className={className}>
            <Styles.RadioInput type='radio' {...restProps} />
            <Styles.RadioButtonCardLabel
                showIcon={!!Icon}
                htmlFor={restProps.id}
            >
                {Icon && <Styles.RadioButtonCardIcon as={Icon} />}
                <Styles.Typography variant='label2'>{label}</Styles.Typography>
                <Styles.SelectedIcon />
            </Styles.RadioButtonCardLabel>
        </div>
    );
}
