import { SelectProps, SelectOption, SelectOptionLabel } from '../types';

export interface UseSelectParams<TOption extends SelectOption>
    extends Pick<
        SelectProps<TOption>,
        'value' | 'onChange' | 'optionLabelSelector' | 'multiSelect' |
        'searchValue'
    > {}

export interface UseSelectPayload<TOption extends SelectOption> {
    isOptionSelected: (option: TOption) => boolean;
    onChangeHandler(selected: TOption | TOption[]): void;
    getOptionLabel: (option: TOption) => SelectOptionLabel;
}

export function useSelect<TOption extends SelectOption>({
    value,
    multiSelect,
    onChange,
    optionLabelSelector,
    searchValue
}: UseSelectParams<TOption>): UseSelectPayload<TOption> {
    const isOptionSelected = (option: TOption) => {
        if (multiSelect) {
            return (value as TOption[])?.includes(option);
        } else {
            return value === option;
        }
    };

    const onChangeHandler = (selected: TOption | TOption[]) => {
        if (!onChange) {
            return;
        }

        onChange(selected);
    };

    const getOptionLabel = (option: TOption) => {
        if (optionLabelSelector) {            
            return optionLabelSelector(option);
        } else {
            return option?.toString() || '';
        }
       
    };

    return {
        isOptionSelected,
        onChangeHandler,
        getOptionLabel,
    };
}
