import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: #4295cd;
    margin-bottom: 5px;
`;

export const SubTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #666666;
`;

export const SubHeader = styled.div`
    font-weight: 600;
    font-size: 24px;
    color: #4295cd;
    margin-top: 20px;
    margin-bottom: 10px;
`;

export const Message = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: #666666;
    max-height: 300px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #4295cd;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const Divider = styled.div`
    border-bottom: 1px dashed #e4e9f3;
    margin-top: 15px;
`;

export const Card = styled.div`
    background: #f5fbff;
    border: 3px solid #4295cd;
    border-radius: 10px;
    padding: 15px;
    display: flex;
`;

export const Image = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: #444444;
`;

export const SubLabel = styled.div`
    font-weight: 500;
    font-size: 12px;
    color: #666666;
`;

export const Content = styled.div`
    margin-left: 10px;
`;

export const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
`;

export const OverallContainer = styled.div``;
