import { CardTheme } from '../../../../models/theme';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';
import { shadows } from '../../../shadows';

export const cardTheme: CardTheme = {
    primary: {
        colors: {
            background: palette.common.white,
        },
        roundRadius: roundRadius.radius2,
        shadows: shadows.style7,
    },
};
