import { ButtonTheme } from '../../../../models/theme/components';
import { ButtonThemeSizeVariants } from '../../../../models/theme/components/ButtonTheme';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';
import { transitions } from '../../../transitions';

const buttonSizes: ButtonThemeSizeVariants = {
    small: {
        width: '120px',
        height: '40px',
    },
    regular: {
        width: '140px',
        height: '40px',
    },
    medium: {
        width: '160px',
        height: '45px',
    },
    large: {
        width: '180px',
        height: '50px',
    },
    extralarge: {
        width: '280px',
        height: '60px',
    },
    content: {
        width: 'auto',
        height: 'auto',
    },
};

export const buttonTheme: ButtonTheme = {
    primary: {
        colors: {
            main: {
                normal: {
                    text: palette.common.white,
                    background: palette.primary.main,
                    border: palette.primary.main,
                    icon: palette.common.white,
                },
                hover: {
                    text: palette.common.white,
                    background: palette.primary.light,
                    border: palette.primary.light,
                    icon: palette.common.white,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
            success: {
                normal: {
                    text: palette.common.white,
                    background: palette.success.main,
                    border: palette.success.main,
                    icon: palette.common.white,
                },
                hover: {
                    text: palette.common.white,
                    background: palette.success.light,
                    border: palette.success.light,
                    icon: palette.common.white,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
            warning: {
                normal: {
                    text: palette.common.white,
                    background: palette.warning.main,
                    border: palette.warning.main,
                    icon: palette.common.white,
                },
                hover: {
                    text: palette.common.white,
                    background: palette.warning.light,
                    border: palette.warning.light,
                    icon: palette.common.white,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
            error: {
                normal: {
                    text: palette.common.white,
                    background: palette.error.main,
                    border: palette.error.main,
                    icon: palette.common.white,
                },
                hover: {
                    text: palette.common.white,
                    background: palette.error.light,
                    border: palette.error.light,
                    icon: palette.common.white,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
        },
        roundRadius: roundRadius.radius1,
        backgroundTransition: transitions.backgroundColorTransition,
        size: buttonSizes,
    },
    secondary: {
        colors: {
            main: {
                normal: {
                    text: palette.primary.main,
                    background: palette.common.linkWater,
                    border: palette.primary.main,
                    icon: palette.primary.main,
                },
                hover: {
                    text: palette.primary.light,
                    background: palette.common.linkWater,
                    border: palette.primary.light,
                    icon: palette.primary.light,
                },
                disabled: {
                    text: palette.common.gray,
                    background: 'transparent',
                    border: palette.action.disabled,
                    icon: palette.common.gray,
                },
            },
            success: {
                normal: {
                    text: palette.success.main,
                    background: palette.common.polar,
                    border: palette.success.main,
                    icon: palette.success.main,
                },
                hover: {
                    text: palette.success.light,
                    background: palette.common.polar,
                    border: palette.success.light,
                    icon: palette.success.light,
                },
                disabled: {
                    text: palette.common.gray,
                    background: 'transparent',
                    border: palette.action.disabled,
                    icon: palette.common.gray,
                },
            },
            warning: {
                normal: {
                    text: palette.warning.main,
                    background: palette.common.tuftBush,
                    border: palette.warning.main,
                    icon: palette.warning.main,
                },
                hover: {
                    text: palette.warning.light,
                    background: palette.common.tuftBush,
                    border: palette.warning.light,
                    icon: palette.warning.light,
                },
                disabled: {
                    text: palette.common.gray,
                    background: 'transparent',
                    border: palette.action.disabled,
                    icon: palette.common.gray,
                },
            },
            error: {
                normal: {
                    text: palette.error.main,
                    background: palette.common.remy,
                    border: palette.error.main,
                    icon: palette.error.main,
                },
                hover: {
                    text: palette.error.light,
                    background: palette.common.remy,
                    border: palette.error.light,
                    icon: palette.error.light,
                },
                disabled: {
                    text: palette.common.gray,
                    background: 'transparent',
                    border: palette.action.disabled,
                    icon: palette.common.gray,
                },
            },
        },
        roundRadius: roundRadius.radius1,
        backgroundTransition: transitions.backgroundColorTransition,
        size: buttonSizes,
    },
    tertiary: {
        colors: {
            main: {
                normal: {
                    text: palette.primary.main,
                    background: 'transparent',
                    border: palette.primary.main,
                    icon: palette.primary.main,
                },
                hover: {
                    text: palette.primary.light,
                    background: 'transparent',
                    border: palette.primary.light,
                    icon: palette.primary.light,
                },
                disabled: {
                    text: palette.common.gray,
                    background: 'transparent',
                    border: palette.action.disabled,
                    icon: palette.common.gray,
                },
            },
            success: {
                normal: {
                    text: palette.success.main,
                    background: 'transparent',
                    border: palette.success.main,
                    icon: palette.success.main,
                },
                hover: {
                    text: palette.success.light,
                    background: 'transparent',
                    border: palette.success.light,
                    icon: palette.success.light,
                },
                disabled: {
                    text: palette.common.gray,
                    background: 'transparent',
                    border: palette.action.disabled,
                    icon: palette.common.gray,
                },
            },
            warning: {
                normal: {
                    text: palette.warning.main,
                    background: 'transparent',
                    border: palette.warning.main,
                    icon: palette.warning.main,
                },
                hover: {
                    text: palette.warning.light,
                    background: 'transparent',
                    border: palette.warning.light,
                    icon: palette.warning.light,
                },
                disabled: {
                    text: palette.common.gray,
                    background: 'transparent',
                    border: palette.action.disabled,
                    icon: palette.common.gray,
                },
            },
            error: {
                normal: {
                    text: palette.error.main,
                    background: 'transparent',
                    border: palette.error.main,
                    icon: palette.error.main,
                },
                hover: {
                    text: palette.error.light,
                    background: 'transparent',
                    border: palette.error.light,
                    icon: palette.error.light,
                },
                disabled: {
                    text: palette.common.gray,
                    background: 'transparent',
                    border: palette.action.disabled,
                    icon: palette.common.gray,
                },
            },
        },
        roundRadius: roundRadius.radius1,
        backgroundTransition: transitions.backgroundColorTransition,
        size: buttonSizes,
    },
    quaternary: {
        colors: {
            main: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.primary.main,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.primary.main,
                },
                disabled: {
                    text: palette.action.disabled,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.action.disabled,
                },
            },
            success: {
                normal: {
                    text: palette.success.main,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.success.main,
                },
                hover: {
                    text: palette.success.main,
                    background: palette.common.polar,
                    border: 'transparent',
                    icon: palette.success.main,
                },
                disabled: {
                    text: palette.action.disabled,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.action.disabled,
                },
            },
            warning: {
                normal: {
                    text: palette.warning.main,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.warning.main,
                },
                hover: {
                    text: palette.warning.main,
                    background: palette.common.tuftBush,
                    border: 'transparent',
                    icon: palette.warning.main,
                },
                disabled: {
                    text: palette.action.disabled,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.action.disabled,
                },
            },
            error: {
                normal: {
                    text: palette.error.main,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.error.main,
                },
                hover: {
                    text: palette.error.main,
                    background: palette.common.remy,
                    border: 'transparent',
                    icon: palette.error.main,
                },
                disabled: {
                    text: palette.action.disabled,
                    background: 'transparent',
                    border: 'transparent',
                    icon: palette.action.disabled,
                },
            },
        },
        roundRadius: roundRadius.radius1,
        backgroundTransition: 'none',
        size: buttonSizes,
    },
};
