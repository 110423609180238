import { ButtonHTMLAttributes } from 'react';
import { LoaderContainer } from './styles';
import { ComponentProps } from '../../common/types';
import * as Styles from './styles';
import { CircleLoader } from '../circle-loader/styles';

export interface ButtonProps
    extends Omit<Styles.ButtonStyleProps, 'colorVariant'>,
        ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: JSX.Element;
    error?: boolean;
    success?: boolean;
    warning?: boolean;
}

export function Button({
    className,
    icon,
    loading,
    children,
    success,
    warning,
    error,
    disableHover,
    iconPosition = 'left',
    variant = 'primary',
    ...restProps
}: ComponentProps<ButtonProps>) {
    const colorVariant =
        (error && 'error') || (success && 'success') || (warning && 'warning') || 'main';

    return (
        <Styles.Button
            className={className}
            loading={loading}
            iconPosition={iconPosition}
            variant={variant}
            disableHover={disableHover}
            colorVariant={colorVariant}
            {...restProps}
        >
            {!loading && icon && (
                <Styles.IconContainer iconPosition={iconPosition} hasChildren={!!children}>
                    {icon}
                </Styles.IconContainer>
            )}
            {loading ? (
                <LoaderContainer>
                    <CircleLoader id='circle-loader' color='#fff' size='30px' />
                </LoaderContainer>
            ) : (
                <>{children}</>
            )}
        </Styles.Button>
    );
}
