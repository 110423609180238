import { useTheme } from 'styled-components';
import { ComponentProps } from '../../../../common/types';
import * as Styles from './styles';
import { Button } from '../../../../components/button';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import ReactECharts from 'echarts-for-react';
import { graphic } from 'echarts';
import { useNavigate } from 'react-router-dom';

export function CampaignPerformance({ className }: ComponentProps) {
    const theme = useTheme();
    const navigate = useNavigate();

    const onHandleAddNew = () => {
        navigate('/campaign-management');
    };

    const option = {
        grid: {
            show: true,
            borderWidth: 1,
            left: 10,
            right: 35,
            top: 10,
            bottom: 10,
            borderColor: 'rgba(0, 0, 0, 0.1)',
            containLabel: true,
        },
        legend: {
            show: false,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [
                '01 Jan ‘22',
                '02 Jan ‘22',
                '03 Jan ‘22',
                '04 Jan ‘22',
                '05 Jan ‘22',
                '06 Jan ‘22',
                '07 Jan ‘22',
            ],
            axisLabel: {
                lineHeight: 18,
                fontSize: 12,
                fontFamily: 'Montserrat',
                fontWeight: 600,
                color: 'rgba(102, 102, 102, 0.95)',
            },
            axisTick: {
                show: false,
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(0, 0, 0, 0.1)',
                },
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: function (yAxisValue: number) {
                    if (yAxisValue !== undefined) {
                        return yAxisValue + ' %';
                    }
                },
                lineHeight: 16,
                fontSize: 12,
                fontFamily: 'Montserrat',
                fontWeight: 600,
                color: 'rgba(102, 102, 102, 0.95)',
            },
        },
        series: [
            {
                data: [67, 20, 30, 45, 60, 70, 90],
                showSymbol: false,
                type: 'line',
                areaStyle: {
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(49, 193, 233, 0.25)',
                        },
                        {
                            offset: 1,
                            color: 'rgba(49, 197, 233, 0)',
                        },
                    ]),
                },
                lineStyle: { color: '#31C5E9' },
            },
        ],
    };

    return (
        <div>
            <Styles.Head>
                <Styles.HeadLeft>
                    <Styles.Title variant='subtitle3' color={theme.palette.common.black}>
                        Campaign Performance
                    </Styles.Title>
                    <Styles.SubTitle variant='label3' color={theme.palette.common.doveGray}>
                        August 16, 2022 - August 23, 2022
                    </Styles.SubTitle>
                </Styles.HeadLeft>
                <Styles.HeadRight>
                    <Styles.MenuDropdown
                        className='category-item-dropdown'
                        placeholder='Select'
                        options={['All Accounts', 'All', 'Accounts']}
                        value={'All Accounts'}
                    />
                    <Styles.MenuDropdown
                        className='mli-data-dropdown'
                        placeholder='Select'
                        options={['No. of Campaigns', 'Campaigns']}
                        value={'No. of Campaigns'}
                    />
                    <Styles.AddNew
                        icon={<PlusIcon />}
                        variant='quaternary'
                        onClick={onHandleAddNew}
                    >
                        Add New
                    </Styles.AddNew>
                </Styles.HeadRight>
            </Styles.Head>
            <ReactECharts
                notMerge={true}
                option={option}
                style={{ height: '400px', width: '100%' }}
            />
        </div>
    );
}
