import styled from 'styled-components';
import { Typography } from '../../../../components/typography';

export const Container = styled.div``;

export const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const Title = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: #222222;
`;

export const View = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: #4295cd;
    cursor: pointer;
`;

export const SummaryItem = styled.div`
    min-height: 70px;
    width: 100%;
    padding: 10px;
    border: 2px solid ${({ theme }) => theme.palette.common.dodgerBlueSub};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.common.aliceBlue};
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    min-width: 183px;
`;

export const SummaryItemLabel = styled(Typography)`
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    color: #444444;
`;

export const SummaryItemValue = styled(Typography)``;

export const IconContainer = styled.div``;

export const SummaryContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    padding-bottom: 10px;
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #08213c;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;
