import { ComponentProps } from '../../common/types';
import * as Styles from './styles';

export interface CollapsibleProps extends Styles.CollapsableStyleProps {
    onCollapseClick: () => void;
    collapsedContent: JSX.Element;
}

export function Collapsible({
    collapsedContent,
    onCollapseClick,
    children,
    ...restProps
}: ComponentProps<CollapsibleProps>) {
    return (
        <Styles.Container {...restProps}>
            <Styles.CollapseIcon />
            <Styles.Content>{children}</Styles.Content>
            <Styles.CollapsedContent>{collapsedContent}</Styles.CollapsedContent>
        </Styles.Container>
    );
}
