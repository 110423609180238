import { MessageTheme } from '../../../../models/theme';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';

export const messageTheme: MessageTheme = {
    primary: {
        colors: {
            main: {
                text: palette.primary.main,
                background: palette.common.aliceBlueSub,
                border: 'none',
            },
            success: {
                text: palette.success.main,
                background: palette.common.polar,
                border: 'none',
            },
            warning: {
                text: palette.warning.main,
                background: palette.common.tuftBush,
                border: 'none',
            },
            error: {
                text: palette.error.main,
                background: palette.common.remy,
                border: 'none',
            },
        },
        roundRadius: roundRadius.radius2,
    },
};
