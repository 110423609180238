import { useRouteMetadata } from '../../common/hooks/useRouteMetadata';
import { ComponentProps } from '../../common/types';
import * as Styles from './styles';
import { ReactComponent as HomeIcon } from './icons/home.svg';
import { useTheme } from 'styled-components';
// import { Path } from '../../app/routing/routes';

export interface BreadcrumbProps {
    noLink?: string;
}

export function Breadcrumb({ className, noLink }: ComponentProps<BreadcrumbProps>) {
    // const { breadcrumbsPath = [] } = useRouteMetadata();
    const theme = useTheme();

    return (
        <Styles.BreadCrumbContainer className={className}>
            <Styles.BreadCrumb>
                <Styles.Link to={'/'}>
                    <HomeIcon />
                </Styles.Link>
                <div>
                    <Styles.Separator>/</Styles.Separator>
                    <Styles.NoLink color={theme.palette.common.gray}>{noLink}</Styles.NoLink>
                </div>
                {/* {breadcrumbsPath.map((link, index) => {
                    const subMenuLink =
                        link.path !== undefined ? (
                            <div key={index}>
                                <Styles.Separator>/</Styles.Separator>
                                <Styles.Link to={link.path}>{link.nestedMenuTitle}</Styles.Link>
                            </div>
                        ) : (
                            <div key={index}>
                                <Styles.Separator>/</Styles.Separator>
                                <Styles.NoLink color={theme.palette.common.gray}>
                                    {link.nestedMenuTitle}
                                </Styles.NoLink>
                            </div>
                        );

                    return subMenuLink;
                })} */}
            </Styles.BreadCrumb>
        </Styles.BreadCrumbContainer>
    );
}
