import { PopupTheme } from '../../../../models/theme/components';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';
import { shadows } from '../../../shadows';
import { transitions } from '../../../transitions';

export const popupTheme: PopupTheme = {
    primary: {
        colors: {
            headerBackground: palette.common.white,
            headerTitle: palette.primary.main,
            footerBackground: palette.common.white,
            background: palette.common.white,
            closeButton: palette.primary.main,
            overlayBackground: 'rgba(0, 0, 0, 0.6)',
        },
        roundRadius: roundRadius.radius2,
        shadow: shadows.style6,
        borderTransition: transitions.colorTransition,
    },
    secondary: {
        colors: {
            headerBackground: palette.common.downriver,
            headerTitle: palette.common.white,
            footerBackground: palette.common.white,
            background: palette.common.white,
            closeButton: palette.common.white,
            overlayBackground: 'rgba(0, 0, 0, 0.6)',
        },
        roundRadius: 'unset',
        shadow: shadows.style6,
        borderTransition: transitions.colorTransition,
    },
};
