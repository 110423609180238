import { Tooltip } from '../tooltip';
import { MutableRefObject, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { ComponentProps } from '../../common/types';
import * as Styles from './styles';

export interface TabsProps {
    activeTabIndex: number;
    onTabClick: (tabIndex: number) => void;
    outletRef?: MutableRefObject<HTMLElement | null>;
}

export interface TabProps {
    id?: string;
    label: string;
    hint?: {
        tooltipText: string;
    };
    renderTabPane?: boolean;
    disabled?: boolean;
}

export function Tab({ renderTabPane = true, children }: ComponentProps<TabProps>) {
    return renderTabPane ? <>{children}</> : null;
}

export function Tabs({
    activeTabIndex,
    onTabClick,
    outletRef,
    children,
    className,
}: ComponentProps<TabsProps>) {
    const panes = children as ReactElement<TabProps>[];
    const activeTabPane = panes.find((pane, i) => i === activeTabIndex);

    return (
        <>
            <Styles.TabNavigation className={className}>
                {panes.map((pane, i) => (
                    <Styles.TabNavigationItem
                        id={pane.props.id}
                        key={pane.props.label}
                        active={activeTabIndex === i}
                        onClick={() => onTabClick(i)}
                        disabled={pane.props.disabled}
                        // style={{ maxWidth: `${100 / panes.length}%` }}
                    >
                        <div>{pane.props.label}</div>

                        {pane.props.hint && (
                            <Tooltip
                                type='secondary'
                                position='right'
                                tooltipContent={pane.props.hint.tooltipText}
                            >
                                <Styles.InfoIcon />
                            </Tooltip>
                        )}
                    </Styles.TabNavigationItem>
                ))}
            </Styles.TabNavigation>
            {outletRef?.current ? createPortal(activeTabPane, outletRef.current) : activeTabPane}
        </>
    );
}
