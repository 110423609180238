import { BreadcrumbTheme } from '../../../../models/theme';
import { shadows } from '../../../shadows';
import { palette } from '../palette';

export const breadcrumbTheme: BreadcrumbTheme = {
    primary: {
        colors: {
            background: palette.common.white,
            text: palette.common.gray,
            link: palette.common.shakespeare,
            separator: palette.common.tundora,
        },
        shadow: shadows.style8,
    },
};
