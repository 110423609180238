import * as Styles from './styles';
import { ReactComponent as FlashIcon } from './icons/flash.svg';
import { RadioButton } from '../../../../components/radio';
import { ReactComponent as TimerIcon } from './icons/timer.svg';
import { FormControl } from '../../../../components/form-control';
import { Input } from '../../../../components/input';

export default function CampaignDetails() {
    return (
        <Styles.Container>
            <Styles.HeaderContainer>
                <div>
                    <Styles.Title>Campaign Details</Styles.Title>
                    <Styles.SubTitle>Provide necessary campaign details</Styles.SubTitle>
                </div>
                <Styles.FakeInput>
                    <FlashIcon />
                    Recommended Opportunities
                    <span>.</span>
                    Lowest Performing Day Part: Late Morning
                </Styles.FakeInput>
            </Styles.HeaderContainer>
            <Styles.Divider />
            <Styles.InputConatiner>
                <div>Campaign Name</div>
                <input value='15% off Melts' />
            </Styles.InputConatiner>
            <Styles.Select>
                <Styles.Title className='auto'>Select Campaign Objective</Styles.Title>
                <Styles.SubTitle>
                    CXi will create a campaign based on your selected objective
                </Styles.SubTitle>
                <Styles.SelectContainer>
                    <Styles.LeftContainer>
                        <Styles.RadioContainer>
                            <Styles.RadioTitle>Regain Customers</Styles.RadioTitle>
                            <RadioButton className='best' id='best' label='Best Customer Rewards' />
                            <RadioButton className='menu' id='menu' label='Menu Item Promotions' />
                            <RadioButton
                                className='store'
                                id='store'
                                label='Store Location Promotions'
                            />
                        </Styles.RadioContainer>
                        <Styles.RadioContainer>
                            <Styles.RadioTitle>Retain Customers</Styles.RadioTitle>
                            <RadioButton
                                className='day'
                                id='day'
                                checked
                                label='Day Part Optimization '
                            />
                            <RadioButton
                                className='item'
                                id='item'
                                label='Menu Item Optimization '
                            />
                        </Styles.RadioContainer>
                        <Styles.RadioContainer>
                            <Styles.RadioTitle>Convert Customers</Styles.RadioTitle>
                            <RadioButton className='new' id='new' label='New Store Openings ' />
                            <RadioButton
                                className='attract'
                                id='attract'
                                label='Attract New Audience '
                            />
                            <RadioButton className='new' id='red' label='Red Napkin ' />
                            <RadioButton
                                className='third'
                                id='third'
                                label='Third-Party Conversion '
                            />
                        </Styles.RadioContainer>
                    </Styles.LeftContainer>
                    <Styles.RadioCard>
                        <TimerIcon />
                        <Styles.RadioLabel>Day Part Optimization</Styles.RadioLabel>
                        <Styles.RadioContent>
                            Increasing the performance for a specific time of day
                        </Styles.RadioContent>
                    </Styles.RadioCard>
                </Styles.SelectContainer>
            </Styles.Select>
            <Styles.Divider />
            <Styles.AutoComplete>
                <Styles.Title className='auto'>Auto-Complete (Optional)</Styles.Title>
                <RadioButton className='camp' id='camp' label='CXi Campaign Creation' />
                <Styles.SubTitle>
                    Using your chosen objective and provided data, CX.i can create campaigns the
                    maxmize its success
                </Styles.SubTitle>
                <RadioButton className='manual' id='manual' label='CXi Campaign Creation' />
            </Styles.AutoComplete>
        </Styles.Container>
    );
}
