import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
    font-weight: 700;
    font-size: 20px;
    color: #4295cd;
    margin-bottom: 5px;
`;

export const SubTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: #666666;
`;

export const SubHeader = styled.div`
    font-weight: 700;
    font-size: 24px;
    color: #4295cd;
    margin: 20px 0px;
`;

export const Message = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: #666666;
    max-height: 300px;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #4295cd;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const Divider = styled.div`
    border-bottom: 1px dashed #e4e9f3;
    margin-top: 15px;
`;
