import styled from 'styled-components';
import { Typography } from '../../../../components/typography';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 15px;
`;

export const SummaryItem = styled.div`
    min-height: 100px;
    width: 100%;
    padding: 10px;
    border: 3px solid ${({ theme }) => theme.palette.common.dodgerBlueSub};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.common.aliceBlue};
    position: relative;
`;

export const SummaryItemLabel = styled(Typography)`
    font-weight: 700;
    font-size: 16px;
    color: #444444;
    width: 100%;
    margin-bottom: 10px;
`;

export const SummaryItemValue = styled(Typography)`
    font-weight: 700;
    font-size: 24px;
    color: #444444;
`;

export const SummaryItemIcon = styled.div`
    position: absolute;
    right: 10px;
    bottom: 10px;
`;
