import { createContext } from 'react';
import { ColorState } from '../../common/types';

export interface FormControlContextValue {
    required?: boolean;
    disabled?: boolean;
    colorState?: ColorState;
}

export interface FormControlProviderProps {
    value: FormControlContextValue;
}

export const FormControlContext = createContext<FormControlContextValue | null>(
    null
);
