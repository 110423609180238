import styled, { css } from 'styled-components';
import { absoluteCentered } from '../../common/styles/mixins';
import { buttonReset } from '../../common/styles/mixins';
import {
    ButtonThemeColorVariants,
    ButtonThemeSizeVariants,
    ButtonThemeStates,
    ButtonTheme,
} from '../../common/models/theme/components/ButtonTheme';
import { Theme } from '../../common/models/theme';

export interface ButtonStyleProps {
    variant?: keyof ButtonTheme;
    colorVariant?: keyof ButtonThemeColorVariants;
    size?: keyof ButtonThemeSizeVariants;
    loading?: boolean;
    light?: boolean;
    disableHover?: boolean;
    iconPosition?: 'left' | 'right';
}

const quaternaryButtonStyles = css`
    border: none;
`;

export const Button = styled.button<ButtonStyleProps>`
    ${buttonReset};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 2px solid;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.button.fontSize};
    font-weight: ${({ theme }) => theme.typography.button.fontWeight};
    line-height: ${({ theme }) => theme.typography.button.lineHeight};

    ${({ theme, variant = 'primary', colorVariant = 'main', size = 'regular', disableHover }) =>
        css`
            ${size === 'content' ? 'max-width: max-content;' : ''};
            min-width: ${theme.components.button[variant].size[size].width};
            height: ${theme.components.button[variant].size[size].height};
            border-radius: ${theme.components.button[variant].roundRadius};
            transition: ${theme.components.button[variant].backgroundTransition};
            ${getStylesByVariantAndState(theme, variant, colorVariant, 'normal')}
            &:hover {
                ${!disableHover &&
                getStylesByVariantAndState(theme, variant, colorVariant, 'hover')}
            }
            &:disabled {
                ${getStylesByVariantAndState(theme, variant, colorVariant, 'disabled')}
            }
        `}

    ${({ variant }) => (variant === 'quaternary' ? quaternaryButtonStyles : '')}

    &[disabled] {
        pointer-events: none;
    }
`;

export const IconContainer = styled.span<{
    iconPosition?: ButtonStyleProps['iconPosition'];
    hasChildren?: boolean;
}>`
    display: flex;
    align-items: center;
    order: ${({ iconPosition }) => (iconPosition === 'left' ? '0' : '1')};
    margin-right: ${({ iconPosition, hasChildren }) =>
        iconPosition === 'right' || !hasChildren ? '0' : '10px'};
    margin-left: ${({ iconPosition, hasChildren }) =>
        iconPosition === 'right' && hasChildren ? '10px' : '0'};
`;

export const LoaderContainer = styled.div`
    ${absoluteCentered};
`;

function getStylesByVariantAndState(
    theme: Theme,
    buttonVariant: keyof ButtonTheme,
    colorVariant: keyof ButtonThemeColorVariants,
    buttonState: keyof ButtonThemeStates
) {
    return css`
        color: ${theme.components.button[buttonVariant].colors[colorVariant][buttonState].text};
        background-color: ${theme.components.button[buttonVariant].colors[colorVariant][buttonState]
            .background};
        border-color: ${theme.components.button[buttonVariant].colors[colorVariant][buttonState]
            .border};
        svg,
        svg path {
            fill: ${({ theme }) =>
                theme.components.button[buttonVariant].colors[colorVariant][buttonState].icon};
        }
    `;
}
