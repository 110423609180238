import * as Styles from './styles';

export default function PublishCampaign() {
    return (
        <Styles.Container>
            <Styles.Title>Publish Campaign</Styles.Title>
            <Styles.SubTitle>
                Please read CX.i terms and conditions before publishing your campaign
            </Styles.SubTitle>
            <Styles.Divider />
            <Styles.SubHeader>Terms and Conditions</Styles.SubHeader>
            <Styles.Message>
                Lorem ipsum dolor sit amet consectetur. Dolor eget nibh odio hendrerit feugiat enim
                adipiscing sed. Egestas enim facilisi scelerisque nisl varius id tortor quisque
                senectus. Varius neque eget venenatis convallis amet vulputate nisi eu diam.
                Placerat ipsum lectus elementum egestas nulla. Ligula felis a urna tortor tellus
                varius consectetur nisi. Consequat leo mauris nulla condimentum ipsum lacus tempor
                vel. Vulputate eleifend libero potenti erat sapien faucibus velit. Aliquam tortor
                justo vitae morbi ut eu tincidunt. Nunc pellentesque dictum ultrices elementum.
                Faucibus odio non auctor adipiscing lacus enim. Venenatis urna nec vel semper dis
                aliquet lectus. Tincidunt faucibus quam duis leo amet augue. Dictumst et cursus quis
                lectus mollis. Maecenas elementum consequat eleifend enim vitae iaculis praesent
                quis. Nulla fames in facilisi enim risus egestas. Senectus elit scelerisque
                convallis orci dolor orci. Urna molestie scelerisque quis pellentesque at nec et.
                Est viverra sagittis viverra vitae augue faucibus adipiscing. Ut eu viverra sit
                velit tellus justo a non id. Convallis egestas ac elementum egestas eget. Vestibulum
                congue in auctor dis dictum. Enim sed at erat eu eu. Morbi habitant ac vitae integer
                hac id eu sed varius. Hendrerit est ultrices bibendum sit in diam. Tincidunt diam
                leo morbi diam rutrum placerat ornare. Aliquam aliquet suspendisse turpis venenatis
                amet auctor. Tincidunt mauris maecenas phasellus dignissim arcu tristique libero
                aenean praesent. A sit id sit aliquam. Adipiscing amet vestibulum cursus vitae.
                Sagittis odio aliquam vulputate risus. Quam phasellus in elit consectetur dis.
                Tincidunt libero amet at a nunc molestie egestas platea placerat. Nunc tellus nisi
                tristique eget eget adipiscing facilisi faucibus. Ac feugiat elit blandit sit sit
                velit quisque proin ultricies. At mauris feugiat velit eu faucibus cursus hac sed
                erat. Pellentesque semper sem nunc pulvinar.
            </Styles.Message>
        </Styles.Container>
    );
}
