import { useEffect, useRef, useMemo, MouseEvent, useState,ChangeEvent } from 'react';
import { useDropdown } from './hooks/useDropdown';
import { CircleLoader } from '../circle-loader/CircleLoader';
import { ComponentProps, SelectOption, SelectProps } from '../../common/types';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { useSelect } from '../../common/hooks/useSelect';
import * as Styles from './styles';
import { Checkbox } from '../checkbox';
import { useTheme } from 'styled-components';
import { Tooltip } from '../tooltip';

export interface DropdownBaseProps<TSelectOption extends SelectOption>
    extends SelectProps<TSelectOption> {
    placeholder?: string;
    loading?: boolean;
    allowNone?: boolean;
    icon?: JSX.Element;
    checkboxAllow?: boolean;
    selectAll?: boolean;
    infoMessage?: string;
    disabled?: boolean;
    isMultiSelectOptionCount?: number;
    selectedOptionCount?: boolean;
    bottomStickyControls?: boolean;
    reset?: boolean;
    apply?: boolean;
    selectedOptionCountText?: string;
    resetValueCount?: number;
    handleApplyStatus?: (status: boolean) => void;
    isDefaultSelected?: boolean;
    defaultValueCount?: number;
    showSearch?:boolean;
    customArray?:{};
    customSearchLabel?: string;
    activeApply? : boolean;
}

export interface DropdownProps<TSelectOption extends SelectOption>
    extends DropdownBaseProps<TSelectOption> {}

export interface MultiselectDropdownProps<TSelectOption extends SelectOption>
    extends DropdownBaseProps<TSelectOption> {}

export function Dropdown<TSelectOption extends SelectOption = string>({
    className,
    placeholder,
    options,
    onChange,
    optionLabelSelector,
    value,
    loading,
    allowNone,
    isOptionDisabled,
    icon,
    showSearch,
    customArray,
    customSearchLabel,
    disabled
}: ComponentProps<DropdownProps<TSelectOption>>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [searchValue, setSearchValue] = useState('');
    const [showSearchInput, setShowSearchInput] = useState(false);
    const { isOptionSelected, onChangeHandler, getOptionLabel } = useSelect<TSelectOption>({
        value,
        onChange,
        optionLabelSelector,
        searchValue
    });
    const [items, setItems] = useState(options);
    const { isOpened, handleDropButtonClick } = useDropdown(containerRef);

    const handleNoneOptionClick = () => {
        if (!!value) {
            onChangeHandler(null as TSelectOption);
        }
    };

    useEffect(() => {
        let values;   
           
        if(!isOpened)
        {
            setSearchValue('')
            return setItems(options);
        }
      
        if(searchValue !== '' && customSearchLabel !== undefined && isOpened)
        {
            setShowSearchInput(true)
           if(customSearchLabel === "locationName")
            return setItems(options?.map((item:any) => item).filter((item:any) => item.locationName.toLowerCase().includes(searchValue.toLowerCase())));
            else if(customSearchLabel === "audienceName")            
            return setItems(options?.map((item:any) => item).filter((item:any) => item.toLowerCase().includes(searchValue.toLowerCase())));
           
        }  

        else
        {
            setShowSearchInput(false)
            return setItems(options);
        }
       
    }, [options,searchValue,isOpened,showSearchInput])


    const handleItemsContainerClick = (event: MouseEvent) => {
    //     if(searchValue !== '')
    //     {
    //     event.preventDefault();
    //     event.stopPropagation();
    // }
        handleDropButtonClick();
    };
    const onHideSearchHandler = () => {
        setSearchValue('');
       return setItems(options)
    }
    function onSearchHandler(event: ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value.toLowerCase());    
        setShowSearchInput(true)
    }
    const handleItemClick = (option: TSelectOption, disabled: boolean) => {
        return function (event: MouseEvent) {
            if (disabled) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
        
            onChangeHandler(option);
           
        };
    };

    return (
        <Styles.Container ref={containerRef} onClick={handleDropButtonClick} className={className}>
            <Styles.DropButton>
                {value ? (
                    <Styles.SelectedValue className='selected-value'>
                        {icon && <Styles.ValueIcon>{icon}</Styles.ValueIcon>}
                        {getOptionLabel(value as TSelectOption)}
                    </Styles.SelectedValue>
                ) : (
                    <Styles.Placeholder>{placeholder}</Styles.Placeholder>
                )}
                {loading ? (
                    <CircleLoader id='dropdown-loader' size={'14px'} />
                ) : (
                    <Styles.DropIcon direction={isOpened ? 'top' : 'bottom'} />
                )}
            </Styles.DropButton>
            {isOpened && !!options.length && !disabled && (
                <Styles.ItemsContainer onClick={handleItemsContainerClick} className='options-area'>
                    {showSearch && (
                        
                        <Styles.SearchInput
                            id='search-input'
                            placeholder='Search'
                            onChange={onSearchHandler}
                            value = {searchValue}
                            icon={!showSearchInput?<SearchIcon/> : <Styles.CrossMarkIcon searchContent = {searchValue}
                                onClick={onHideSearchHandler}
                                className='cross-mark-icon' /> }  />
                    )}
                      {showSearch  && searchValue !== '' && items.length ==0 &&
                (<Styles.NoMatches>No Record Found</Styles.NoMatches>)}
                    {searchValue === '' && allowNone && (
                        <Styles.DropdownItem
                            id='none-option'
                            key='none'
                            selected={!value}
                            onClick={handleNoneOptionClick}
                        >
                            None
                        </Styles.DropdownItem>
                    )}
                    {items.map((option, i) => {
                      
                        const optionId = (getOptionLabel(option) + '-option')
                            .toLowerCase()
                            .replaceAll(' ', '');
                        const disabled = !!isOptionDisabled ? isOptionDisabled(option) : false;
                        return (
                            <Styles.DropdownItem
                                id={optionId}
                                key={i}
                                disabled={disabled}
                                selected={isOptionSelected(option)}
                                onClick={handleItemClick(option, disabled)}
                            >
                                {getOptionLabel(option)}
                            </Styles.DropdownItem>
                        );
                    })}
                </Styles.ItemsContainer>
            )}
        </Styles.Container>
    );
}

export function MultiselectDropdown<TSelectOption extends SelectOption = string>({
    placeholder,
    className,
    options,
    onChange,
    optionLabelSelector,
    value,
    multiSelect,
    loading,
    allowNone,
    checkboxAllow,
    selectAll,
    infoMessage,
    disabled,
    isMultiSelectOptionCount,
    selectedOptionCount,
    bottomStickyControls,
    reset,
    apply,
    selectedOptionCountText,
    resetValueCount,
    handleApplyStatus,
    isDefaultSelected,
    defaultValueCount,
    showSearch,
    customArray,
    activeApply
}: ComponentProps<MultiselectDropdownProps<TSelectOption>>) {
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);
    const [searchValue, setSearchValue] = useState('');
    const { isOptionSelected, onChangeHandler, getOptionLabel } = useSelect<TSelectOption>({
        value,
        onChange,
        optionLabelSelector,
        multiSelect,searchValue
    });
    const { isOpened, handleDropButtonClick } = useDropdown(containerRef);
    const [showAllBox, setShowAllBox] = useState(false);
    const [multiOptionDisable, setMultiOptionDisable] = useState(false);
    const [selectOptionValueCount, setSelectedOptionValueCount] = useState<number>();
    const [resetOptionDisable, setResetOptionDisable] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [items, setItems] = useState(options);

    useEffect(() => {
        let values;           
        if(!isOpened)
        {
            setSearchValue('')
            return setItems(options);
        }
     
        if(searchValue !== '')
        {
            setShowSearchInput(true)
            if(customArray)
            {
                setShowSearchInput(true)
            let location_id:any= []
            values = Object.values(customArray);
            const final_Array =  values?.map((item:any) => item).filter((item:any) => item.toLowerCase().includes(searchValue.toLowerCase()))
            final_Array.map((element:any)=>
            {
                
                location_id.push(getKeyByValue(customArray,element));
            })
            return setItems(location_id)
            }
          
        return setItems(options.map((item:any) => item).filter((item:any) => item.toLowerCase().includes(searchValue.toLowerCase())));
        }  
        // else
        // {
        //     setShowSearchInput(false)
        //     return setItems(options);
        // }
        
    }, [options,searchValue,isOpened,showSearchInput])

    const onHideSearchHandler = () => {
        setSearchValue('');
        setMultiOptionDisable(false);
        return setItems(options);
        // event.preventDefault();
        // event.stopPropagation();
        // handleDropButtonClick();
    }
    function onSearchHandler(event: ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value.toLowerCase());   
        setShowSearchInput(true) 
        if(event.target.value !== '')
        {
            handleApplyStatus?.(false);
        setItems(options.map((item:any) => item).filter((item:any) => item.toLowerCase().includes(event.target.value.toLowerCase())));
        }  
        
    }
    useEffect(() => {
        const updatedSelectedItems =
            (value as TSelectOption[])?.filter((item) => options.includes(item)) || [];
           
            // setItems(updatedSelectedItems.filter((item) => item.toLowerCase().includes(searchValue)));
        setSelectedOptionValueCount(updatedSelectedItems.length);
        if ((value as TSelectOption[]) !== undefined && isMultiSelectOptionCount !== undefined) {
            if ((value as TSelectOption[]).length < isMultiSelectOptionCount) {
                setMultiOptionDisable(false);
            } else {
                setMultiOptionDisable(true);
            }
        }

        if (updatedSelectedItems.length !== (value as TSelectOption[])?.length) {
            setMultiOptionDisable(false);
            onChangeHandler(updatedSelectedItems);
        }

        //reset button disable condition
        const resetDisableState =
            options.slice(0, resetValueCount).sort().join(',') === [value].sort().join(',');
        setResetOptionDisable(resetDisableState);
      
       
    }, [options, value,showSearchInput]);
    useEffect(() => {       
        if (defaultValueCount !== 0 && isDefaultSelected === true) {
            const defaultValues = options.slice(0, defaultValueCount);
            onChangeHandler(defaultValues);
        }
    }, [defaultValueCount]);
    
    const valueText = useMemo<string | undefined>(
        () => (value as TSelectOption[])?.map((item) => getOptionLabel(item)).join(','),
        [value]
    );
    const handleItemClick = (item: TSelectOption) => {
     
        let updatedItems: TSelectOption[];
        if ((value as TSelectOption[])?.includes(item)) {
            updatedItems = (value as TSelectOption[]).filter((i) => i !== item);
            setShowAllBox(false);
            setMultiOptionDisable(false);
            handleApplyStatus?.(false);
        } else {
            updatedItems = [...((value as TSelectOption[]) || []), item];
            handleApplyStatus?.(false);
        }
        onChangeHandler(updatedItems);
        handleApplyStatus?.(false);
        if(searchValue !== '')
        { let values;
            if(customArray)
            {
            let location_id:any= []
            values = Object.values(customArray);
            const final_Array =  values?.map((item:any) => item).filter((item:any) => item.toLowerCase().includes(searchValue.toLowerCase()))
            final_Array.map((element:any)=>
            {
                
                location_id.push(getKeyByValue(customArray,element));
            })
             return setItems(location_id)
            }
            const updatedSelectedItems =
            (value as TSelectOption[])?.filter((item) => options.includes(item)) || [];
            return setItems(options.map((item:any) => item).filter((item:any) => item.toLowerCase().includes(searchValue.toLowerCase())));
        }  
    };

    const handleNoneOptionClick = () => {
        if ((value as TSelectOption[])?.length) {
            onChangeHandler([]);
            setShowAllBox(false);
            setMultiOptionDisable(false);
        }
    };

    const onHandleApplyClick = () => {
        onChangeHandler((value as TSelectOption[]) || []);
        handleApplyStatus?.(true);
        handleDropButtonClick();
    };

    const onHandleResetClick = () => {
        let updatedItems: TSelectOption[];
        const resetItems = options.slice(0, resetValueCount);
        updatedItems = resetItems;
        onChangeHandler(updatedItems);
        handleApplyStatus?.(false);
    };

    const handleShowallOptionClick = () => {
        let updatedItems: TSelectOption[];
        if ((value as TSelectOption[])?.length === 0) {
            setShowAllBox(true);
            updatedItems = [...options];
            onChangeHandler(updatedItems);
        } else {
            setShowAllBox(false);
            onChangeHandler([]);
        }
        setSearchValue('')
    };

    return (
        <Styles.Container ref={containerRef} className={className}>
            <Styles.DropButton onClick={handleDropButtonClick} disabled={disabled}>
                {(value as TSelectOption[])?.length ? (
                    <Styles.SelectedValue className='selected-value'>
                        {selectedOptionCount ? (
                            <>
                            
                                <Styles.SelectedValueCount
                                    variant='heading5'
                                    color={theme.palette.common.shakespeare}
                                >
                                    {selectOptionValueCount !== 0    && selectOptionValueCount !== options.length &&
                                     ('0' + `${selectOptionValueCount}`).slice(-2)}
                                     
                                </Styles.SelectedValueCount>    
                                {selectedOptionCountText !== undefined &&
                                selectedOptionCountText?.length > 0 ? (
                                    <Tooltip type='secondary' tooltipContent={valueText}>
                                        <>{selectedOptionCountText}</>
                                    </Tooltip>
                                ) : (
                                    <> {selectOptionValueCount !== options.length ?
                                        <>
                                        {selectOptionValueCount !== 0 && selectOptionValueCount === 1 ?  <>{valueText}</>: <>{placeholder} Selected</>}</>
                                       :
                                            <>
                                            <Styles.SelectedValueCount
                                                variant='heading5'
                                                color={theme.palette.common.shakespeare}
                                            >All
                                            </Styles.SelectedValueCount>{placeholder}</>}</>
                                )}
                            </>
                        ) : (
                            <> {selectOptionValueCount !== options.length?
                            <>
                            {selectOptionValueCount === 1 ?  <>{valueText}</>: <> <Styles.SelectedValueCount
                                    variant='heading5'
                                    color={theme.palette.common.shakespeare}
                                > 
                                      {selectOptionValueCount !== 0 &&
                                        ('0' + `${selectOptionValueCount}`).slice(-2)}
                                    {/* {valueText} */}
                                </Styles.SelectedValueCount>{placeholder} Selected</>}</>
                           :
                                <>
                                <Styles.SelectedValueCount
                                    variant='heading5'
                                    color={theme.palette.common.shakespeare}
                                >All
                                </Styles.SelectedValueCount>{placeholder}</>}</>



                        )}
                    </Styles.SelectedValue>
                ) : (
                    <>
                    {selectedOptionCountText !== undefined &&
                                selectedOptionCountText?.length > 0 ? <>{selectedOptionCountText}</> :  <Styles.Placeholder>{placeholder || ''}</Styles.Placeholder>}
                    </>
                   
                )}
                
                {loading ? (
                    <CircleLoader size={'14px'} />
                ) : (
                    <Styles.DropIcon direction={isOpened ? 'top' : 'bottom'} />
                )}
            </Styles.DropButton>
            {isOpened && !!options.length && (
                
                    
                        <>  <Styles.ItemsContainer className='options-area'>
                        <Styles.HeaderItem>
                    {showSearch  && (
                        <Styles.SearchInput
                            id='search-input'
                            placeholder='Search'
                            onChange={onSearchHandler}
                            value = {searchValue}
                            icon={!showSearchInput?<SearchIcon/> : <Styles.CrossMarkIcon searchContent = {searchValue}
                            onClick={onHideSearchHandler}
                            className='cross-mark-icon' /> }  />
                    )}
                </Styles.HeaderItem>
                {showSearch  && searchValue !== '' && items.length == 0 &&
                (<Styles.NoMatches>No Record Found</Styles.NoMatches>)}
                        {searchValue === '' && allowNone && (
                            <Styles.DropdownItem
                                id='none-option'
                                key='none'
                                selected={!(value as TSelectOption[])?.length}
                                onClick={handleNoneOptionClick}
                            >
                                {checkboxAllow && (
                                    <Checkbox
                                        id='none-option'
                                        key='none'
                                        checked={!(value as TSelectOption[])?.length}
                                        onChange={handleNoneOptionClick} />
                                )}{' '}
                                None
                            </Styles.DropdownItem>
                        )}
                        {selectAll && (
                            <Styles.DropdownItem
                                id='Show All-option'
                                key='Show All'
                                selected={showAllBox}
                                onClick={handleShowallOptionClick}
                            >
                                {checkboxAllow && (
                                    <Checkbox
                                        id='Show All-option'
                                        key='Show All'
                                        checked={showAllBox}
                                        onChange={handleShowallOptionClick} />
                                )}{' '}
                                Select All
                            </Styles.DropdownItem>
                        )}
                        {infoMessage && searchValue !== '' && items.length !== 0 && (
                            <Styles.DropdownItem
                                id='info-message'
                                key='Info Message'
                                className='info-message'
                            >
                                <Styles.InfoMessageIcon></Styles.InfoMessageIcon>
                                {infoMessage}
                            </Styles.DropdownItem>
                        )}
                        {items.map((item, i) => {
                            const optionId = (getOptionLabel(item) + '-option'  + i)
                                .toLowerCase()
                                .replaceAll(' ', '');
                            return (
                                <>
                                    <Styles.DropdownItem
                                        id={optionId}
                                        key={i}
                                        selected={isOptionSelected(item)}
                                        onClick={() => handleItemClick(item)}
                                        isMultiOptionDisabled={multiOptionDisable && !isOptionSelected(item)}
                                    >
                                        {checkboxAllow && (
                                            <Styles.multiCheckbox
                                                id={optionId}
                                                key={i}
                                                checked={isOptionSelected(item)}
                                                onChange={() => handleItemClick(item)}
                                                isMultiCheckboxDisabled={multiOptionDisable && !isOptionSelected(item)} />
                                        )}{' '}
                                        {getOptionLabel(item)}
                                    </Styles.DropdownItem>
                                </>
                            );
                        })}
                        {bottomStickyControls && (
                            <Styles.bottomStickyControls hideControl={apply && reset}>
                                {reset && (
                                    <Styles.DropdownItem
                                        id='reset-option'
                                        key='reset'
                                        onClick={onHandleResetClick}
                                        className={resetOptionDisable
                                            ? 'reset-option disabled'
                                            : 'reset-option'}
                                    >
                                        Reset
                                    </Styles.DropdownItem>
                                )}
                                {apply && (
                                    <Styles.DropdownItem
                                        id='apply-option'
                                        key='apply'
                                        onClick={onHandleApplyClick}
                                        className={(value as TSelectOption[])?.length === 0
                                            ? 'apply-option disabled'
                                            : 'apply-option'}
                                    >
                                        Apply
                                    </Styles.DropdownItem>
                                )}
                            </Styles.bottomStickyControls>
                        )}
                    </Styles.ItemsContainer></>
            )}
        </Styles.Container>
    );
}
function getKeyByValue(object:any, value:any) {
    return Object.keys(object).find(key => object[key] === value);
  }