import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: #4295cd;
    margin-bottom: 5px;
    margin-top: 35px;
`;

export const TopTitle = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: #4295cd;
    margin-bottom: 5px;
`;

export const SubTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #666666;
   

`;

export const Divider = styled.div`
    border-bottom: 1px dashed #e4e9f3;
    margin-top: 15px;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FakeInput = styled.div`
    border: 2px solid #4295cd;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    color: #4295cd;
    height: 44px;
    padding: 10px 15px;
    gap: 10px;
    display: flex;
    align-items: center;
`;

export const AutoComplete = styled.div`
    .auto {
        padding-top: 25px;
    }
    .manual {
        padding-top: 30px;
    }
    .camp {
        padding-top: 15px;
    }
`;

export const Select = styled.div`
    label {
        margin-bottom: 10px;
    }
`;
export const RadioTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    padding: 20px 0px;
`;
export const UploadContainer = styled.div`
    padding-left: 20px;
    margin-top: 30px;
    width: 30%;
    height: auto;
`;
export const UploadBox = styled.div`
    margin-top: 10px;
    height: 70%
`;
export const RadioContainer = styled.div``;
export const SelectContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 150%;
`;

export const RadioLabel = styled.div`
    font-weight: 600;
    font-size: 14px;
    text-align: center;

    color: #444444;
`;
export const RadioContent = styled.div`
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #666666;
`;
export const LeftContainer = styled.div`
    display: flex;
    gap: 40px;
    width: 70%;
    height: 100%;
`;

export const InputConatiner = styled.div`
    width: 100%;
    padding: 35px 0px 0px 0px;

    div {
        font-weight: 600;
        font-size: 14px;
        color: #4295cd;
        margin-bottom: 5px;
    }

    input {
        width: 400px;
        font-weight: 600;
        font-size: 16px;
        color: #444444;
        border-width: 0px 0px 1px 0px;
        border-color: #4295cd;
    }
`;

export const InputFullConatiner = styled.div`
    width: 100%;
    padding: 35px 0px 0px;

    div {
        font-weight: 600;
        font-size: 14px;
        color: #4295cd;
        margin-bottom: 5px;
    }

    input {
        width: 96%;
        font-weight: 600;
        font-size: 16px;
        color: #444444;
        border-width: 0px 0px 1px 0px;
        border-color: #4295cd;
    }
`;
export const ImageView = styled.div`
    width:100%;
    img {
        width: 90%;
    }
`;
