import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation } from 'swiper';
import { ReactComponent as ArrowIcon } from '../../assets/icons/left-arrow.svg';
import { ComponentProps } from '../../common/types';
import { Chip } from '../chip';
import * as Styles from './styles';

type SelectionItem<TItemType> = TItemType extends Array<any>
    ? NonNullable<TItemType[number]>
    : NonNullable<TItemType>;

export interface SelectedFiltersBarProps<TSelection extends object> {
    selectedFilters?: TSelection;
    labelSelectors?: {
        [K in keyof TSelection]?: (filter: SelectionItem<TSelection[K]>) => string;
    };
    onSelectedFiltersChange?: (filters: TSelection) => void;
}

export function SelectedFiltersBar<TSelection extends object>({
    selectedFilters,
    labelSelectors,
    onSelectedFiltersChange,
    className,
}: ComponentProps<SelectedFiltersBarProps<TSelection>>) {
    return (
        <Styles.Container className={className}>
            <Chip error className='clearAll-button'>
                Clear all
            </Chip>
            <Styles.FiltersDivider vertical />
            <Styles.ChipContainer>
                <Chip
                    success
                    className='selected-filter'
                    showDelete={true}
                    // onDelete={() => handleRemoveFilterClick(filter)}
                >
                    Last 30 Days
                </Chip>
                <Chip
                    success
                    className='selected-filter'
                    showDelete={true}
                    // onDelete={() => handleRemoveFilterClick(filter)}
                >
                    North West
                </Chip>
                <Chip
                    success
                    className='selected-filter'
                    showDelete={true}
                    // onDelete={() => handleRemoveFilterClick(filter)}
                >
                    Oregon
                </Chip>
                <Chip
                    success
                    className='selected-filter'
                    showDelete={true}
                    // onDelete={() => handleRemoveFilterClick(filter)}
                >
                    Portland
                </Chip>
                <Chip
                    success
                    className='selected-filter'
                    showDelete={true}
                    // onDelete={() => handleRemoveFilterClick(filter)}
                >
                    135246
                </Chip>
            </Styles.ChipContainer>
            {/* <Styles.SwiperPrevButton className='swiper-prev-button'>
                <ArrowIcon />
            </Styles.SwiperPrevButton>
            <Swiper
                direction='horizontal'
                slidesPerView='auto'
                spaceBetween={10}
                mousewheel={true}
                navigation={{
                    prevEl: '.swiper-prev-button',
                    nextEl: '.swiper-next-button',
                }}
                modules={[Navigation, Mousewheel]}
            >
                {Object.entries(selectedFilters)
                    .filter(([, value]) => !!value)
                    .map(([key, value]) => {
                        const filters = Array.isArray(value) ? value : [value];
                        const labelSelector = labelSelectors?.[key as keyof TSelection];

                        return filters.map((filter) => {
                            const customDateRangeClass = JSON.stringify(
                                Object.getOwnPropertyDescriptor(filter, 'startDate')?.configurable
                            );
                            return (
                                <SwiperSlide key={filter} className={customDateRangeClass}>
                                    <Chip
                                        success
                                        className='selected-filter'
                                        showDelete={true}
                                        onDelete={() => handleRemoveFilterClick(filter)}
                                    >
                                        {labelSelector?.(filter) || filter.toString()}
                                    </Chip>
                                </SwiperSlide>
                            );
                        });
                    })}
            </Swiper>
            <Styles.SwiperNextButton className='swiper-next-button'>
                <ArrowIcon />
            </Styles.SwiperNextButton> */}
        </Styles.Container>
    );
}
