import * as Styles from './styles';
import bees from './icons/bees.png';
import bing from './icons/bing.png';
import door from './icons/door.png';
import facebook from './icons/facebook.png';
import freewheel from './icons/freewheel.png';
import google from './icons/google.png';
import instagram from './icons/instagram.png';
import linkedin from './icons/linkedin.png';
import snapchat from './icons/snapchat.png';
import ticktok from './icons/tcktok.png';
import yahoo from './icons/yahoo.png';
import youtube from './icons/youtube.png';

export default function SelectPlatform() {
    return (
        <Styles.Container>
            <Styles.Title>Select Platform</Styles.Title>
            <Styles.SubTitle>
                Select the platform that is the best fit for your business goal
            </Styles.SubTitle>
            <Styles.Divider />
            <Styles.OverallContainer>
                <Styles.SubHeader>Social</Styles.SubHeader>
                <Styles.CardContainer>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={facebook} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>Facebook</Styles.Label>
                            <Styles.SubLabel>
                                social media platform for creating, sharing and discovering short
                                videos.
                            </Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={instagram} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>Instagram</Styles.Label>
                            <Styles.SubLabel>
                                social media platform for creating, sharing and discovering short
                                videos.
                            </Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={linkedin} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>LinkedIn</Styles.Label>
                            <Styles.SubLabel>
                                reach executives and the most relevant industry viewers
                            </Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={snapchat} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>Snapchat</Styles.Label>
                            <Styles.SubLabel>
                                social media platform for creating, sharing and discovering short
                                videos.
                            </Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={ticktok} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>TikTok</Styles.Label>
                            <Styles.SubLabel>
                                social media platform for creating, sharing and discovering short
                                videos.
                            </Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={youtube} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>YouTube</Styles.Label>
                            <Styles.SubLabel>
                                Videos ads displaying across relevant content.
                            </Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                </Styles.CardContainer>
            </Styles.OverallContainer>

            <Styles.OverallContainer>
                <Styles.SubHeader>Web</Styles.SubHeader>
                <Styles.CardContainer>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={bees} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>Beeswax</Styles.Label>
                            <Styles.SubLabel>Beeswax</Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={bing} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>Bing Ads</Styles.Label>
                            <Styles.SubLabel>Banner ads across the web</Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={door} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>DoorDash</Styles.Label>
                            <Styles.SubLabel>
                                Platform showcasing restaurant menu items and stores for eaters
                            </Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={freewheel} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>Freewheel</Styles.Label>
                            <Styles.SubLabel>Banner ads acrosss multiple websites</Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={google} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>Google Ads</Styles.Label>
                            <Styles.SubLabel>
                                Banner ads across one of the most used search engines
                            </Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                    <Styles.Card>
                        <Styles.Image>
                            <img src={yahoo} />
                        </Styles.Image>
                        <Styles.Content>
                            <Styles.Label>Yahoo Ads</Styles.Label>
                            <Styles.SubLabel>Banner ads across the web</Styles.SubLabel>
                        </Styles.Content>
                    </Styles.Card>
                </Styles.CardContainer>
            </Styles.OverallContainer>
        </Styles.Container>
    );
}
