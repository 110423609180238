import { css } from 'styled-components';

export const listReset = css`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const linkReset = css`
    text-decoration: none;
    color: inherit;

    &:active {
        color: inherit;
    }
`;

export const buttonReset = css`
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`;

export const absoluteCentered = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const absoluteCenteredVertical = css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

export const absoluteCenteredHorizontal = css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;

export const displayContentCenter = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const displayContentSpaceBetween = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
