import * as Styles from './styles';

import { useTheme } from 'styled-components';
import { DashboardLayout } from '../../components/dashboard-layout';
import { SelectedFiltersBar } from '../../components/selected-filters-bar/SelectedFiltersBar';
import { FilterBar } from '../../components/filter-bar/FilterBar';
import { KpiTiles } from './components/kpi-tiles/KpiTiles';
import { CampaignPerformance } from './components/campaign-performance/CampaignPerformance';
import { ConnectedPlatforms } from './components/connected-platforms/ConnectedPlatforms';
import { RecentCampaigns } from './components/recent-campaigns/RecentCampaigns';
import { Header } from '../../components/header';
import { Tab } from '../../components/tabs';
import { useState } from 'react';

export default function Dashboard() {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const theme = useTheme();
    const handleTabClick = (tabIndex: number) => {
        setActiveTabIndex(tabIndex);
    };

    return (
        <>
            <Header title='Dashboard' />
            <div>
                <Styles.BaseTabs activeTabIndex={activeTabIndex} onTabClick={handleTabClick}>
                    <Tab
                        label='CMI: Campaign Management Insight'
                        id='cmi-tab'
                        renderTabPane={false}
                    ></Tab>
                    <Tab
                        label='TMI: Total Marketing Insight'
                        id='tmi-tab'
                        renderTabPane={false}
                        disabled={true}
                    ></Tab>
                    <Tab
                        label='CTI: Customer Transactional Insight'
                        id='cti-tab'
                        renderTabPane={false}
                        disabled={true}
                    ></Tab>
                    <Tab
                        label='MLI: Menu Level Insight'
                        id='mli-tab'
                        renderTabPane={false}
                        disabled={true}
                    ></Tab>
                </Styles.BaseTabs>
            </div>
            <DashboardLayout filterBar={<FilterBar />} selectedFiltersBar={<SelectedFiltersBar />}>
                <KpiTiles />
                <Styles.BlockDivider />
                <CampaignPerformance />
                <Styles.BlockDivider />
                <ConnectedPlatforms />
                <Styles.BlockDivider />
                <RecentCampaigns />
            </DashboardLayout>
        </>
    );
}
