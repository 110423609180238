import { HighlightedTheme } from '../../../../models/theme';
import { palette } from '../palette';

export const highlightedTheme: HighlightedTheme = {
    primary: {
        colors: {
            background: palette.common.roseBud,
        },
    },
};
