import * as Styles from './styles';
import facebook from './icons/facebook.png';
import yahoo from './icons/yahoo.png';
import ticktok from './icons/ticktok.png';
import google from './icons/google.png';
import instagram from './icons/instagram.png';
import mailchimp from './icons/mailchim.png';

export function ConnectedPlatforms() {
    return (
        <Styles.Container>
            <Styles.Head>
                <Styles.Title>Top Ranking</Styles.Title>
                <Styles.View>view all</Styles.View>
            </Styles.Head>
            <Styles.SummaryContainer>
                <Styles.SummaryItem>
                    <Styles.IconContainer>
                        <img src={google} />
                    </Styles.IconContainer>
                    <Styles.SummaryItemLabel tag='div'>Google Ads</Styles.SummaryItemLabel>
                </Styles.SummaryItem>
                <Styles.SummaryItem>
                    <Styles.IconContainer>
                        <img src={ticktok} />
                    </Styles.IconContainer>
                    <Styles.SummaryItemLabel tag='div'>TikTok</Styles.SummaryItemLabel>
                </Styles.SummaryItem>
                <Styles.SummaryItem>
                    <Styles.IconContainer>
                        <img src={facebook} />
                    </Styles.IconContainer>
                    <Styles.SummaryItemLabel tag='div'>Facebook</Styles.SummaryItemLabel>
                </Styles.SummaryItem>
                <Styles.SummaryItem>
                    <Styles.IconContainer>
                        <img src={mailchimp} />
                    </Styles.IconContainer>
                    <Styles.SummaryItemLabel tag='div'>MailChimp</Styles.SummaryItemLabel>
                </Styles.SummaryItem>
                <Styles.SummaryItem>
                    <Styles.IconContainer>
                        <img src={instagram} />
                    </Styles.IconContainer>
                    <Styles.SummaryItemLabel tag='div'>Instagram</Styles.SummaryItemLabel>
                </Styles.SummaryItem>
                <Styles.SummaryItem>
                    <Styles.IconContainer>
                        <img src={yahoo} />
                    </Styles.IconContainer>
                    <Styles.SummaryItemLabel tag='div'>Yahoo !</Styles.SummaryItemLabel>
                </Styles.SummaryItem>
            </Styles.SummaryContainer>
        </Styles.Container>
    );
}
