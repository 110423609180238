import styled from 'styled-components';
import { Button } from '../button';
import { Divider } from '../divider';
import { ReactComponent as RemoveIcon } from '../../assets/icons/remove.svg';
import { buttonReset } from '../../common/styles/mixins';

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .swiper {
        margin-left: 0;
    }

    .swiper-slide {
        width: auto;
    }
    .swiper-slide.true {
        display: none;
    }
`;

export const FiltersDivider = styled(Divider)`
    height: 20px;
    margin: 0 10px;
`;

export const FilterButton = styled(Button)`
    cursor: default;
`;

export const RemoveFilterIcon = styled(RemoveIcon)`
    cursor: pointer;
`;

const SwiperNavigationButton = styled.button`
    ${buttonReset};
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.palette.common.whiteIce};
    border: 1px solid ${({ theme }) => theme.palette.common.persianGreen};
    border-radius: 50%;
    transition: opacity linear 0.2s;

    &[disabled] {
        opacity: 0.6;
        cursor: default;
    }
`;

export const SwiperPrevButton = styled(SwiperNavigationButton)`
    margin-right: 10px;
`;

export const SwiperNextButton = styled(SwiperNavigationButton)`
    margin-left: 10px;
    transform: rotate(180deg);
`;

export const ChipContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
