import { BreadcrumbTheme } from '../../../../models/theme';
import { shadows } from '../../../shadows';
import { palette } from '../palette';

export const breadcrumbTheme: BreadcrumbTheme = {
    primary: {
        colors: {
            background: palette.common.white,
            text: palette.common.dustyGray,
            link: palette.common.roseBud,
            separator: palette.common.red,
        },
        shadow: shadows.style8,
    },
};
