import * as Styles from './styles';
import { ComponentProps } from '../../common/types';
import { CircleLoader } from '../circle-loader/CircleLoader';
import { CircleLoaderProps } from '../circle-loader/styles';

export interface LoadableProps {
    loading?: boolean;
    blur?: boolean;
    loader?: CircleLoaderProps;
    showBlurLoader?: boolean;
}

export function Loadable({
    blur,
    loading,
    children,
    loader,
    showBlurLoader,
    className,
}: ComponentProps<LoadableProps>) {
    if (blur) {
        return (
            <Styles.MainBlurableContainer className={className}>
                {loading && showBlurLoader && (
                    <Styles.BlurableCircleLoader
                        {...loader}
                        className='blurable-circle-loader'
                    />
                )}
                <Styles.BlurableContainer loading={loading}>
                    {children}
                </Styles.BlurableContainer>
            </Styles.MainBlurableContainer>
        );
    } else {
        return loading ? (
            <Styles.LoadingContainer className={className}>
                <CircleLoader {...loader} />
            </Styles.LoadingContainer>
        ) : (
            <>{children}</>
        );
    }
}
