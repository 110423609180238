import styled from 'styled-components';
import { ReactComponent as DoubleArrowIcon } from '../../assets/icons/double-left-arrow.svg';

export interface CollapsableStyleProps {
    collapsed: boolean;
    collapsedWidth: string;
    expandedWidth: string;
}

export const Content = styled.div`
    flex-shrink: 0;
    transition: opacity linear 0.3s;
`;

export const CollapsedContent = styled.div`
    padding-top: 35px;
    transition: margin-left ease-in-out 0.4s, opacity linear 0.5s;
`;

export const CollapseIcon = styled(DoubleArrowIcon)`
    position: absolute;
    top: 0;
    width: 14px;
    height: 14px;
    cursor: pointer;
    transition: transform linear 0.4s;
`;

export const Container = styled.div<CollapsableStyleProps>`
    position: relative;
    display: flex;
    height: 100%;
    width: ${(props) =>
        props.collapsed ? props.collapsedWidth : props.expandedWidth};
    //overflow: hidden;
    transition: width ease-in-out 0.4s;

    ${Content} {
        width: ${({ expandedWidth }) => expandedWidth};
        opacity: ${({ collapsed }) => (collapsed ? '0' : '1')};
        pointer-events: ${({ collapsed }) => (collapsed ? 'none' : 'auto')};
    }

    ${CollapsedContent} {
        width: ${({ collapsedWidth }) => collapsedWidth};
        margin-left: ${(props) =>
            props.collapsed ? `-${props.expandedWidth}` : '0'};
        opacity: ${({ collapsed }) => (collapsed ? '1' : '0')};
    }

    ${CollapseIcon} {
        transform: ${({ collapsed }) =>
            collapsed ? 'rotate(180deg)' : 'none'};
        right: ${({ collapsed }) => (collapsed ? '2px' : '0')};
    }
`;
