import { NavBarTheme } from '../../../../models/theme';
import { palette } from '../palette';

export const navBarTheme: NavBarTheme = {
    primary: {
        colors: {
            normal: {
                text: palette.common.white,
                background: 'transparent',
                border: 'transparent',
                icon: palette.common.java,
            },
            hover: {
                text: palette.common.white,
                background: palette.common.madison,
                border: 'transparent',
                icon: palette.common.white,
            },
            selected: {
                text: palette.common.white,
                background: palette.common.shakespeare,
                border: palette.common.white,
                icon: palette.common.white,
            },
        },
    },
};
