import { Spacing } from '../models/theme';

export const spacing: Spacing = {
    form: {
        spacing1: '4px',
        spacing2: '8px',
        spacing3: '10px',
    },
    components: {
        spacing1: '20px',
        spacing2: '30px',
        spacing3: '40px',
        spacing4: '60px',
        spacing5: '80px',
        spacing6: '120px',
        spacing7: '15px',
    },
};
