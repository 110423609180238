import { CheckboxTheme } from '../../../../models/theme/components';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';

export const checkboxTheme: CheckboxTheme = {
    primary: {
        colors: {
            normal: {
                label: palette.common.doveGray,
                background: palette.common.white,
                border: palette.primary.main,
            },
            checked: {
                label: palette.primary.main,
                background: palette.primary.main,
                border: palette.primary.main,
            },
        },
        iconColor: palette.common.white,
        roundRadius: roundRadius.radius5,
    },
};
