import { ChipTheme } from '../../../../models/theme';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';
import { transitions } from '../../../transitions';

export const chipTheme: ChipTheme = {
    primary: {
        colors: {
            light: {
                normal: {
                    text: palette.common.gray,
                    background: palette.common.white,
                    border: palette.common.black1,
                    icon: palette.common.black1,
                },
                hover: {
                    text: palette.common.gray,
                    background: palette.common.white,
                    border: palette.common.black1,
                    icon: palette.common.black1,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
            main: {
                normal: {
                    text: palette.common.tundora,
                    background: palette.common.white,
                    border: palette.common.shakespeare,
                    icon: palette.common.shakespeare,
                },
                hover: {
                    text: palette.common.tundora,
                    background: palette.common.white,
                    border: palette.common.shakespeare,
                    icon: palette.common.shakespeare,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
            success: {
                normal: {
                    text: palette.common.astral,
                    background: palette.common.aliceBlue,
                    border: palette.common.astral,
                    icon: palette.common.astral,
                },
                hover: {
                    text: palette.common.astral,
                    background: palette.common.aliceBlue,
                    border: palette.common.astral,
                    icon: palette.common.astral,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
            error: {
                normal: {
                    text: palette.common.brinkPinkSub,
                    background: palette.common.snow,
                    border: palette.common.brinkPinkSub,
                    icon: palette.common.brinkPinkSub,
                },
                hover: {
                    text: palette.common.brinkPinkSub,
                    background: palette.common.snow,
                    border: palette.common.brinkPinkSub,
                    icon: palette.common.brinkPinkSub,
                },
                disabled: {
                    text: palette.common.brinkPinkSub,
                    background: palette.common.snow,
                    border: palette.common.brinkPinkSub,
                    icon: palette.common.brinkPinkSub,
                },
            },
        },
        roundRadius: roundRadius.radius1,
        backgroundTransition: transitions.backgroundColorTransition,
    },
    secondary: {
        colors: {
            light: {
                normal: {
                    text: palette.common.gray,
                    background: palette.common.white,
                    border: palette.common.black1,
                    icon: palette.common.black1,
                },
                hover: {
                    text: palette.common.gray,
                    background: palette.common.white,
                    border: palette.common.black1,
                    icon: palette.common.black1,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
            main: {
                normal: {
                    text: palette.common.tundora,
                    background: palette.common.white,
                    border: palette.common.shakespeare,
                    icon: palette.common.shakespeare,
                },
                hover: {
                    text: palette.common.tundora,
                    background: palette.common.white,
                    border: palette.common.shakespeare,
                    icon: palette.common.shakespeare,
                },
                disabled: {
                    text: palette.common.white,
                    background: palette.common.bombay,
                    border: palette.common.bombay,
                    icon: palette.common.white,
                },
            },
            success: {
                normal: {
                    text: palette.common.shakespeare,
                    background: palette.common.linkWater,
                    border: palette.common.shakespeare,
                    icon: palette.common.shakespeare,
                },
                hover: {
                    text: palette.common.shakespeare,
                    background: palette.common.linkWater,
                    border: palette.common.shakespeare,
                    icon: palette.common.shakespeare,
                },
                disabled: {
                    text: palette.common.shakespeare,
                    background: palette.common.linkWater,
                    border: palette.common.shakespeare,
                    icon: palette.common.shakespeare,
                },
            },
            error: {
                normal: {
                    text: palette.common.brinkPinkSub,
                    background: palette.common.snow,
                    border: palette.common.brinkPinkSub,
                    icon: palette.common.brinkPinkSub,
                },
                hover: {
                    text: palette.common.brinkPinkSub,
                    background: palette.common.snow,
                    border: palette.common.brinkPinkSub,
                    icon: palette.common.brinkPinkSub,
                },
                disabled: {
                    text: palette.common.brinkPinkSub,
                    background: palette.common.snow,
                    border: palette.common.brinkPinkSub,
                    icon: palette.common.brinkPinkSub,
                },
            },
        },
        roundRadius: roundRadius.radius1,
        backgroundTransition: transitions.backgroundColorTransition,
    },
};
