import styled, { css } from 'styled-components';
import { ReactComponent as BaseCrossMarkIcon } from '../../assets/icons/cross-mark.svg';
import { Theme } from '../../common/models/theme';
import {
    ChipTheme,
    ChipThemeColorVariants,
    ChipThemeStates,
} from '../../common/models/theme/components/ChipTheme';
import { buttonReset, displayContentCenter } from '../../common/styles';
import { colors } from '../../common/styles';

export interface ChipStyleProps {
    variant?: keyof ChipTheme;
    colorVariant?: keyof ChipThemeColorVariants;
    disableHover?: boolean;
    iconPosition?: 'left' | 'right';
}

const secondaryButtonStyles = css`
    padding: 10px;
    height: 40px;
`;
const primaryCrossMarkIcon = css`
    border-radius: 50%;
    padding: 2px;
    border: 0.5px solid ${colors.astral};
`;

export const Button = styled.button<ChipStyleProps>`
    ${buttonReset};
    position: relative;
    ${displayContentCenter}
    padding: 5px 8px;
    border: 1px solid;
    cursor: pointer;
    font-size: ${({ theme }) => theme.typography.chip.fontSize};
    font-weight: ${({ theme }) => theme.typography.chip.fontWeight};
    line-height: ${({ theme }) => theme.typography.chip.lineHeight};
    min-width: 80px;
    min-height: 30px;
  
    border-radius: 4px;
    ${({ theme, variant = 'primary', colorVariant = 'light', disableHover }) =>
        css`
            transition: ${theme.components.button[variant]
                .backgroundTransition};
            ${getStylesByVariantAndState(
                theme,
                variant,
                colorVariant,
                'normal'
            )}
            &:hover {
                ${!disableHover &&
                getStylesByVariantAndState(
                    theme,
                    variant,
                    colorVariant,
                    'hover'
                )}
            }
            &:disabled {
                ${getStylesByVariantAndState(
                    theme,
                    variant,
                    colorVariant,
                    'disabled'
                )}
            }
        `}

    ${({ variant }) => (variant === 'secondary' ? secondaryButtonStyles : '')}
    &[disabled] {
        pointer-events: none;
    }
`;

export const IconContainer = styled.span<{
    iconPosition?: ChipStyleProps['iconPosition'];
}>`
    display: flex;
    align-items: center;
    order: ${({ iconPosition }) => (iconPosition === 'left' ? '0' : '1')};
    margin-right: ${({ iconPosition }) =>
        iconPosition === 'right' ? '0' : '10px'};
    margin-left: ${({ iconPosition }) =>
        iconPosition === 'right' ? '10px' : '0'};
`;

export const CrossMarkIcon = styled(BaseCrossMarkIcon)<{ variant: string }>`
    ${({ variant }) => (variant === 'secondary' ? '' : primaryCrossMarkIcon)}
    width: 14px;
    height: 14px;
    cursor: pointer;
`;

function getStylesByVariantAndState(
    theme: Theme,
    chipVariant: keyof ChipTheme,
    chipColorVariant: keyof ChipThemeColorVariants,
    chipState: keyof ChipThemeStates
) {
    return css`
        color: ${theme.components.chip[chipVariant].colors[chipColorVariant][
            chipState
        ].text};
        background-color: ${theme.components.chip[chipVariant].colors[
            chipColorVariant
        ][chipState].background};
        border-color: ${theme.components.chip[chipVariant].colors[
            chipColorVariant
        ][chipState].border};
        svg,
        svg path {
            fill: ${({ theme }) =>
                theme.components.chip[chipVariant].colors[chipColorVariant][
                    chipState
                ].icon};
        }
    `;
}
