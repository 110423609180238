import * as Styles from './styles';
import { ComponentProps } from '../../common/types';
import { ButtonHTMLAttributes } from 'react';
import { useEffect, useState } from 'react';

export interface ChipProps
    extends Omit<Styles.ChipStyleProps, 'colorVariant'>,
        ButtonHTMLAttributes<HTMLButtonElement> {
    main?: boolean;
    success?: boolean;
    error?: boolean;
    showDelete?: boolean;
    onDelete?: () => void;
    icon?: JSX.Element;
    customWidth? :boolean;
}

export function Chip({
    className,
    iconPosition = 'right',
    main,
    success,
    error,
    variant = 'primary',
    disableHover,
    children,
    showDelete,
    icon,
    customWidth,
    onDelete = () => {},
    ...restProps
}: ComponentProps<ChipProps>) {
    
    const colorVariant =
        (main && 'main') ||
        (success && 'success') ||
        (error && 'error') ||
        'light';
    return (
        <Styles.Button
            className={className}
            iconPosition={iconPosition}
            variant={variant}
            disableHover={disableHover}
            colorVariant={colorVariant}    
            {...restProps}
        >
            {icon && (
                <Styles.IconContainer iconPosition={iconPosition}>
                    {icon}
                </Styles.IconContainer>
            )}
            {showDelete && (
                <Styles.IconContainer iconPosition={iconPosition}>
                    <Styles.CrossMarkIcon
                        className='cross-mark-icon'
                        variant={variant}
                        onClick={onDelete}
                    />
                </Styles.IconContainer>
            )}
            <>{children}</>
        </Styles.Button>
    );
}
