import { TextareaTheme } from '../../../../models/theme/components';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';
import { transitions } from '../../../transitions';

export const textareaTheme: TextareaTheme = {
    primary: {
        colors: {
            main: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.primary.main,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.primary.light,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.primary.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
            custom: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.common.bombay,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.common.bombay,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.primary.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
            success: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.success.main,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.success.light,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.success.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
            warning: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.warning.main,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.warning.light,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.warning.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
            error: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.error.main,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.error.light,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.error.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
        },
        iconColor: palette.primary.main,
        roundRadius: 'initial',
        borderTransition: transitions.colorTransition,
    },
    secondary: {
        colors: {
            main: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.primary.main,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.primary.light,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.primary.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
            custom: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.common.bombay,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.common.bombay,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.primary.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
            success: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.success.main,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.success.light,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.success.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
            warning: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.warning.main,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.warning.light,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.warning.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
            error: {
                normal: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.error.main,
                    placeholder: palette.common.dustyGray,
                },
                hover: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.error.light,
                    placeholder: palette.common.dustyGray,
                },
                focus: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.error.light,
                    placeholder: palette.common.dustyGray,
                },
                disabled: {
                    text: palette.common.tundora,
                    background: 'transparent',
                    border: palette.action.disabled,
                    placeholder: palette.action.disabled,
                },
            },
        },
        iconColor: palette.primary.main,
        roundRadius: roundRadius.radius1,
        borderTransition: transitions.colorTransition,
    },
};
