import { useState } from 'react';
import { Breadcrumb } from '../../components/breadcrumb';
import { Header } from '../../components/header';
import { PageLayout } from '../../components/page-layout/styles';
import { CampaignStepper } from './components/campaign-stepper/CampaignStepper';
import PublishCampaign from './components/publish-campaign/PublishCampaign';
import SelectPlatform from './components/select-platform/SelectPlatform';
import * as Styles from './styles';

export default function CampaignManagement() {
    const [active, setActive] = useState(-1);
    return (
        <Styles.Container>
            <Header title='Campaign Management' />
            <Breadcrumb noLink='Campaign Management' />
            <PageLayout className='step'>
                <CampaignStepper active={active} setActive={setActive}/>
                
                {/* <PublishCampaign /> */}
            </PageLayout>
        </Styles.Container>
    );
}
