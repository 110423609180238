import styled from 'styled-components';
import { buttonReset, absoluteCenteredHorizontal } from '../../common/styles';
import { ReactComponent as ArrowIcon } from '../../assets/icons/down-arrow.svg';
import { ReactComponent as BaseInfoIcon } from './icons/info-message.svg';
import { Checkbox } from '../checkbox';
import { Typography } from '../typography/Typography';
import { Input } from '../input';
import { ReactComponent as BaseCrossMarkIcon } from '../../assets/icons/cross-mark.svg';

export const Container = styled.div`
    min-width: 185px;
    position: relative;
    z-index: 10;
    font-size: ${({ theme }) => theme.typography.dropdown.fontSize};
    font-weight: ${({ theme }) => theme.typography.dropdown.fontWeight};
    line-height: ${({ theme }) => theme.typography.dropdown.lineHeight};
`;

export const DropButton = styled.button.attrs({
    type: 'button',
}) <{ disabled?: boolean }>`
    ${buttonReset};
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid;
    border-radius: ${({ theme }) => theme.components.dropdown.primary.roundRadius};
    background-color: ${({ theme }) => theme.components.dropdown.primary.colors.normal.background};
    text-align: left;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')}}
    opacity: ${({ disabled }) => (disabled ? '50%' : '100%')};
    border-color: ${({ disabled, theme }) =>
        disabled
            ? `${theme.palette.common.dustyGray}`
            : `${theme.components.dropdown.primary.colors.normal.border}`};
`;

export const DropIcon = styled(ArrowIcon) <{ direction?: 'top' | 'bottom' }>`
    flex-shrink: 0;
    width: 11px;
    margin-left: 5px;
    stroke: ${({ theme }) => theme.components.dropdown.primary.colors.normal.icon};
    transform: ${(props) => (props.direction === 'top' ? 'rotate(180deg)' : '')};
`;

export const InfoMessageIcon = styled(BaseInfoIcon)``;

export const ItemsContainer = styled.div`
    ${absoluteCenteredHorizontal};
    z-index: 10;
    top: 100%;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    margin-top: 5px;
    color: red;
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: ${({ theme }) => theme.components.dropdownOptions.primary.roundRadius};
    border: 1px solid ${({ theme }) => theme.components.dropdownOptions.primary.borderColor};
`;

export const DropdownItem = styled.div<{
    selected?: boolean;
    disabled?: boolean;
    isMultiOptionDisabled?: boolean;
}>`
    padding: 8px 16px;
    word-break: break-word;
    display: flex;
    color: ${({ theme, disabled, isMultiOptionDisabled }) =>
        disabled || isMultiOptionDisabled
            ? theme.palette.common.darkGray
            : theme.components.dropdownOptions.primary.textColor};
    cursor: ${({ disabled, isMultiOptionDisabled }) =>
        disabled || isMultiOptionDisabled ? 'default' : 'pointer'};
    pointer-events: ${({ isMultiOptionDisabled }) => (isMultiOptionDisabled ? 'none' : '')};
    transition: ${({ theme }) => theme.components.dropdownOptions.primary.backgroundTransition};

    background-color: ${(props) =>
        props.selected
            ? ({ theme }) => theme.components.dropdownOptions.primary.colors.selected.background
            : ({ theme }) => theme.components.dropdownOptions.primary.colors.normal.background};

    &:hover {
        background-color: ${(props) =>
        props.selected
            ? ({ theme }) => theme.components.dropdownOptions.primary.colors.selected.background
            : ({ theme }) => theme.components.dropdownOptions.primary.colors.hover.background};
    }
    &.info-message {
        border-bottom: 2px solid ${({ theme }) => theme.palette.common.dodgerBlueSub};
        color: ${({ theme }) => theme.palette.common.astral};
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: default;
        pointer-events: none;
    }
    &.reset-option {
        color: #ff5174;
    }
    &.reset-option.disabled,
    &.apply-option.disabled {
        color: #afafaf;
        background: #afafaf1c;
        cursor: default;
        pointer-events: none;
    }
    &.apply-option {
        color: #00baa0;
    }

    transition: ${({ theme }) => theme.components.dropdownOptions.primary.backgroundTransition};
`;

export const bottomStickyControls = styled.div<{
    hideControl?: boolean;
}>`
    border-top: 2px solid ${({ theme }) => theme.palette.common.dodgerBlueSub};
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: #ffffff;
    div {
        position: relative;
        width: ${(props) => (props.hideControl ? '50%' : '100%')};
        justify-content: center;
        &:first-child::after {
            content: '|';
            position: absolute;
            right: 0;
            color: #e6e6e6;
        }
        &:hover {
            background: transparent !important;
        }
    }
`;
export const Placeholder = styled.div`
    color: ${({ theme }) => theme.components.dropdown.primary.colors.normal.placeholder};
`;

export const SelectedValue = styled.div`
    color: ${({ theme }) => theme.components.dropdown.primary.colors.normal.selectedValue};
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const ValueIcon = styled.span`
    display: inline-flex;
    margin-right: 5px;
`;

export const multiCheckbox = styled(Checkbox) <{ isMultiCheckboxDisabled?: boolean }>`
    pointer-events: ${(props) => (props.isMultiCheckboxDisabled ? 'none' : '')};
    label::before {
        border-color: ${({ theme, isMultiCheckboxDisabled }) =>
        isMultiCheckboxDisabled ? theme.palette.common.darkGray : ''};
    }
    & .multi-checkbox {
        pointer-event: none;
    }
`;

export const SelectedValueCount = styled(Typography)`
    display: inline-flex;
    margin-right: 5px;
`;
export const NoMatches = styled.div` 
display: flex;
justify-content: center;
align-items: center;
height:100px;
color: ${({ theme }) => theme.components.dropdown.primary.colors.normal.placeholder};
font-weight:600;
font-size:14px;
`;
export const HeaderItem = styled.div`
    min-width:90px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid ${({ theme }) => theme.palette.common.catskillWhite};

    &:first-child {
        border: none;
    }
`;
export const SearchInput = styled(Input)`
max-width: 100%;
min-width:90px;
    display: flex;
    padding: 5px 5px;

    &:hover,
    &:focus {
        padding: 5px 5px;
    }
`;
export const CrossMarkIcon = styled(BaseCrossMarkIcon) <{ searchContent?: string }>`
    cursor: pointer;
    width: 12px;
    height: 12px;
    display :  ${(props) => (props.searchContent === '' ? 'none' : 'block')};
`;

export const SearchIcon = styled(BaseCrossMarkIcon) <{ searchContent?: string }>`
    cursor: pointer;
    width: 12px;
    height: 12px;
    display :  ${(props) => (props.searchContent === '' ? 'none' : 'block')};
`;

