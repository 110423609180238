import { useState } from 'react';
import { ComponentProps } from '../../common/types';
import { Collapsible } from '../collapsible';
import { Loadable } from '../loadable';
import { Divider } from '../divider';
import * as Styles from './styles';

export interface FilterProps {
    title: string;
    icon?: string | JSX.Element;
    noBorder?: boolean;
}

export interface FilterBarProps {
    loading?: boolean;
}

export function Filter({
    title,
    className,
    children,
    icon,
    noBorder,
}: ComponentProps<FilterProps>) {
    return (
        <>
            {!noBorder && <Divider />}
            <Styles.FilterContainer className={className} customhheight={noBorder}>
                <Styles.FilterTitle>
                    {title} <Styles.FilterIcon>{icon}</Styles.FilterIcon>
                </Styles.FilterTitle>
                {children}
            </Styles.FilterContainer>
        </>
    );
}

export function FilterBar({ loading, className, children }: ComponentProps<FilterBarProps>) {
    const [collapsed, setCollapsed] = useState(false);
    const handleCollapseClick = () => setCollapsed((prevCollapsed) => !prevCollapsed);

    return (
        <Styles.Container className={className}>
            <Collapsible
                collapsed={true}
                onCollapseClick={handleCollapseClick}
                collapsedContent={
                    <>
                        <Divider />
                        <Styles.CollapsedContent>Expand To View Filters</Styles.CollapsedContent>
                    </>
                }
                collapsedWidth='20px'
                expandedWidth='220px'
            >
                <Styles.FiltersHeading>Filters</Styles.FiltersHeading>
                <Loadable loading={loading}>{children}</Loadable>
            </Collapsible>
        </Styles.Container>
    );
}
