import { ThemeComponents } from '../../../../models/theme';
import { inputTheme } from './inputTheme';
import { tooltipTheme } from './tooltipTheme';
import { radioButtonTheme } from './radioButtonTheme';
import { dropdownTheme } from './DropdownTheme';
import { dropdownOptionsTheme } from './dropdownOptionsTheme';
import { checkboxTheme } from './checkboxTheme';
import { toastTheme } from './toastTheme';
import { datatableTheme } from './dataTableTheme';
import { buttonTheme } from './buttonTheme';
import { popupTheme } from './popupTheme';
import { cardHeaderTheme } from './cardHeaderTheme';
import { pageLayoutTheme } from './pageLayoutTheme';
import { highlightedTheme } from './highlightedTheme';
import { cardTheme } from './cardTheme';
import { headerTheme } from './headerTheme';
import { navBarTheme } from './navBarTheme';
import { chipTheme } from './chipTheme';
import { textareaTheme } from './textareaTheme';
import { messageTheme } from './messageTheme';
import { breadcrumbTheme } from './breadcrumbTheme';
import { rangeSliderTheme } from './rangeSlidereTheme';

export const components: ThemeComponents = {
    input: inputTheme,
    tooltip: tooltipTheme,
    radioButton: radioButtonTheme,
    dropdown: dropdownTheme,
    checkbox: checkboxTheme,
    dropdownOptions: dropdownOptionsTheme,
    toast: toastTheme,
    dataTable: datatableTheme,
    button: buttonTheme,
    popup: popupTheme,
    cardHeader: cardHeaderTheme,
    pageLayout: pageLayoutTheme,
    highlighted: highlightedTheme,
    card: cardTheme,
    header: headerTheme,
    navBar: navBarTheme,
    chip: chipTheme,
    textarea: textareaTheme,
    message: messageTheme,
    breadcrumb: breadcrumbTheme,
    rangeSlider: rangeSliderTheme,
};
