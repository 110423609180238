import { ComponentProps } from '../../common/types';
import * as Styles from './styles';
import { ReactComponent as HFilterIcon } from './icons/hfilter.svg';

export interface DashboardLayoutProps {
    filterBar?: JSX.Element;
    selectedFiltersBar?: JSX.Element;
    layoutType?: string;
    layoutRight?: JSX.Element;
}

export function DashboardLayout({
    filterBar: FilterBarContent,
    children,
    className,
    selectedFiltersBar: selectedFilterBar,
    layoutType = 'primary',
    layoutRight,
}: ComponentProps<DashboardLayoutProps>) {
    return (
        <Styles.Container className={className} layoutType={layoutType}>
            {selectedFilterBar}
            <Styles.ContentWrapper>
                {layoutType === 'secondary' && (
                    <Styles.TopFilter>
                        <Styles.HLabel>
                            <HFilterIcon /> Filters
                        </Styles.HLabel>
                        <Styles.HRight>{layoutRight}</Styles.HRight>
                    </Styles.TopFilter>
                )}
                <Styles.Content>
                    {FilterBarContent}
                    <Styles.TabPaneContainer>{children}</Styles.TabPaneContainer>
                </Styles.Content>
            </Styles.ContentWrapper>
        </Styles.Container>
    );
}
