import styled, { css } from 'styled-components';
import { CircleLoader } from '../circle-loader/CircleLoader';
import { Theme } from '../../common/models/theme';
import {
    InputThemeColorVariants,
    InputThemeStates,
    InputTheme,
} from '../../common/models/theme/components/InputTheme';

export interface InputStyleProps {
    variant?: keyof InputTheme;
    loading?: boolean;
    disabled?: boolean;
    colorVariant?: keyof InputThemeColorVariants;
    iconPosition?: 'left' | 'right';
}

const primaryInputStyles = (disabled?: boolean) => css`
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid;
    margin-bottom: 1px;
    padding: 10px 0;

    &:focus,
    &:active {
        outline: none;
        border-bottom: 2px solid;
        margin-bottom: 0;
    }

    &:hover {
        outline: none;
        border-bottom: ${disabled ? '1px' : '2px'} solid;
        margin-bottom: ${disabled ? '1px' : 0};
    }

    &:disabled {
        outline: none;
    }
`;

const secondaryInputStyles = css`
    border: 2px solid;
    padding: 10px;

    &:focus,
    &:active {
        outline: none;
        box-shadow: ${({ theme }) => theme.shadows.style2};
    }

    &:hover {
        outline: none;
    }

    &:disabled {
        outline: none;
    }
`;

export const RelativeInputContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const Input = styled.input<InputStyleProps>`
    width: 100%;
    min-width: 250px;
    display: inline-flex;
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
    line-height: ${({ theme }) => theme.typography.body1.lineHeight};

    ${({ variant, disabled }) =>
        variant === 'primary' ? primaryInputStyles(disabled) : secondaryInputStyles}
    ${({ iconPosition }) => (iconPosition === 'left' ? 'padding-left: 35px;' : '')};

    ${({ theme, variant = 'primary', colorVariant = 'main' }) =>
        css`
            border-radius: ${({ theme }) => theme.components.input[variant].roundRadius};
            transition: ${({ theme }) => theme.components.input[variant].borderTransition};
            ${getStylesByVariantAndState(theme, variant, colorVariant, 'normal')}

            &:focus,
            &:active {
                ${getStylesByVariantAndState(theme, variant, colorVariant, 'focus')}
            }

            &:hover {
                ${getStylesByVariantAndState(theme, variant, colorVariant, 'hover')}
            }

            &:disabled {
                ${getStylesByVariantAndState(theme, variant, colorVariant, 'disabled')}
            }
        `}
        ${({ theme, variant = 'secondary', colorVariant = 'main' }) =>
        css`
            border-radius: ${({ theme }) => theme.components.input[variant].roundRadius};
            transition: ${({ theme }) => theme.components.input[variant].borderTransition};
            ${getStylesByVariantAndState(theme, variant, colorVariant, 'normal')}

            &:focus,
            &:active {
                ${getStylesByVariantAndState(theme, variant, colorVariant, 'focus')}
            }

            &:hover {
                ${getStylesByVariantAndState(theme, variant, colorVariant, 'hover')}
            }

            &:disabled {
                ${getStylesByVariantAndState(theme, variant, colorVariant, 'disabled')}
            }
        `}

    &[type='number'] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            display: none;
        }
    }
`;

export const InputLoader = styled(CircleLoader)`
    position: absolute;
    right: 15px;
    top: 13px;
    width: 20px;
    height: 20px;
`;

export const InputIcon = styled.div<{
    iconPosition?: InputStyleProps['iconPosition'];
    variant: keyof InputTheme;
}>`
    position: absolute;
    ${({ iconPosition }) => (iconPosition === 'right' ? 'right: 10px;' : 'left:10px')};
    top: 50%;
    display: inline-flex;
    & svg,
    & svg path {
        fill: ${({ theme, variant }) => theme.components.input[variant].iconColor};
    }
    & svg {
        transform: translateY(-50%);
    }
`;

function getStylesByVariantAndState(
    theme: Theme,
    inputVariant: keyof InputTheme,
    colorVariant: keyof InputThemeColorVariants,
    inputState: keyof InputThemeStates
) {
    return css`
        color: ${theme.components.input[inputVariant].colors[colorVariant][inputState].text};
        background-color: ${theme.components.input[inputVariant].colors[colorVariant][inputState]
            .background};
        border-color: ${theme.components.input[inputVariant].colors[colorVariant][inputState]
            .border};

        &::placeholder {
            color: ${({ theme }) =>
                theme.components.input[inputVariant].colors[colorVariant][inputState].placeholder};
        }
    `;
}
