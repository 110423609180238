import { ToastTheme } from '../../../../models/theme/components';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';
import { shadows } from '../../../shadows';
import { transitions } from '../../../transitions';

export const toastTheme: ToastTheme = {
    primary: {
        colors: {
            main: {
                background: palette.common.aliceBlueSub,
                border: palette.primary.main,
                icon: palette.common.doveGray,
                text: palette.common.doveGray,
            },
            success: {
                background: palette.common.clearDay,
                border: palette.common.aquamarine,
                icon: palette.common.doveGray,
                text: palette.common.doveGray,
            },
            warning: {
                background: palette.common.tuftBush,
                border: palette.warning.main,
                icon: palette.common.doveGray,
                text: palette.common.doveGray,
            },
            error: {
                background: palette.common.remy,
                border: palette.error.main,
                icon: palette.common.doveGray,
                text: palette.common.doveGray,
            },
            custom:
            {
                background: palette.common.approxCanary,
                border: palette.common.deepLemon,
                icon: palette.common.doveGray,
                text: palette.common.doveGray,
            }
        },
        roundRadius: roundRadius.radius1,
        shadows: shadows.style6,
        backgroundTransition: transitions.backgroundColorTransition,
    },
};
