import styled from 'styled-components';

export const Container = styled.div`
    flex-shrink: 0;
    padding: 15px 10px 20px;
    margin-right: 20px;
    border-radius: 4px;
    background: ${({ theme }) => theme.palette.background.block};
    box-shadow: 0px 1px 1px ${({ theme }) => theme.palette.common.mercury};
`;

export const CollapsedContent = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    writing-mode: vertical-rl;
    text-orientation: mixed;
`;

export const FiltersHeading = styled.span`
    display: block;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.palette.common.tundora};
    font-size: ${({ theme }) => theme.typography.subtitle5.fontSize};
    font-weight: ${({ theme }) => theme.typography.subtitle5.fontWeight};
    line-height: ${({ theme }) => theme.typography.subtitle5.lineHeight};
`;

export const FilterTitle = styled.span`
    display: block;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.typography.subtitle6.fontSize};
    font-weight: ${({ theme }) => theme.typography.subtitle6.fontWeight};
    line-height: ${({ theme }) => theme.typography.subtitle6.lineHeight};
`;

export const FilterContainer = styled.div<{customhheight ?: boolean}>`
    margin: ${({ customhheight }) => (customhheight ? '13px 0' : '20px 0')} ;
`;
export const FilterIcon = styled.span`
    vertical-align: text-top;
`;
