import { DropdownOptionsTheme } from '../../../../models/theme';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';
import { transitions } from '../../../transitions';

export const dropdownOptionsTheme: DropdownOptionsTheme = {
    primary: {
        colors: {
            normal: {
                background: palette.common.white,
            },
            hover: {
                background: palette.common.aliceBlueSub,
            },
            selected: {
                background: palette.common.solitude,
            },
        },
        textColor: palette.common.doveGray,
        roundRadius: roundRadius.radius1,
        backgroundTransition: transitions.backgroundColorTransition,
        borderColor: 'rgba(0, 0, 0, 0.2)',
    },
};
