import { colors } from './colors';

export const chartsPalette = [
    colors.azureRadiance,
    colors.heliotrope,
    colors.caribbeanGreenSub1,
    colors.cerulean,
    colors.vividTangerine,
    colors.funBlue,
    colors.mustard,
    colors.seaGreen,
    colors.suriousBlue,
    colors.anakiwa,
];

export const layout = {
    headerHeight: '60px',
    navbarWidth: '200px',
};
export const specialEventColorPalette = [
    colors.caribbeanGreenSub1,
    colors.persianPink,
    colors.vividTangerine,
    colors.funBlue,
    colors.lemon,
    colors.mediumSeaGreen,
    colors.coral,
];
