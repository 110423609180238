import * as Styles from './styles';
import { Typography as TypographyModel } from '../../common/models/theme';
import { ComponentProps } from '../../common/types';

const tagNamesByVariant: any = {
    heading1: 'h1',
    heading2: 'h2',
    heading3: 'h3',
    heading4: 'h5',
};

export interface TypographyProps {
    variant?: keyof TypographyModel;
    color?: string;
    tag?: keyof JSX.IntrinsicElements;
}

export function Typography({
    variant = 'body1',
    color,
    tag,
    id,
    className,
    children,
}: ComponentProps<TypographyProps>) {
    let TagName: keyof JSX.IntrinsicElements = 'span';
    if (tag) {
        TagName = tag;
    } else if (tagNamesByVariant[variant]) {
        TagName = tagNamesByVariant[variant];
    }

    return (
        <Styles.Typography
            variant={variant}
            color={color}
            as={TagName}
            className={className}
            id={id}
        >
            {children}
        </Styles.Typography>
    );
}
