import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as NotificationsIcon } from './icons/notifications.svg';
import { ReactComponent as SignOutIcon } from './icons/sign-out.svg';
import { Typography } from '../typography';
import { useTheme } from 'styled-components';
import { IconButton } from '../icon-button';
import { Tooltip } from '../tooltip';
import * as Styles from './styles';

export interface HeaderProps {
    title?: string;
}

export function Header({ title }: HeaderProps) {
    const theme = useTheme();

    return (
        <Styles.Container className='header' HeaderWidth={false}>
            <Typography
                variant='subtitle2'
                id='page-name-header'
                color={theme.components.header.primary.colors.text}
                tag='div'
            >
                {title}
            </Typography>
            <Styles.ActionContainer>
                <Styles.IconContainer>
                    <IconButton id='sign-out-button' title='Sign out'>
                        <SearchIcon />
                    </IconButton>
                </Styles.IconContainer>
                <Styles.IconContainer>
                    <IconButton id='sign-out-button' title='Sign out'>
                        <NotificationsIcon />
                    </IconButton>
                </Styles.IconContainer>
                <Styles.IconContainer>
                    <Tooltip type='secondary' position='bottom' tooltipContent='Test User'>
                        <Styles.ProfileIcon active={true}>
                            <Typography variant='body1' color={theme.palette.common.shakespeare}>
                                TU
                            </Typography>
                        </Styles.ProfileIcon>
                    </Tooltip>
                </Styles.IconContainer>
                <Styles.IconContainer>
                    <IconButton id='sign-out-button' title='Sign out'>
                        <SignOutIcon />
                    </IconButton>
                </Styles.IconContainer>
            </Styles.ActionContainer>
        </Styles.Container>
    );
}

function getUserInitials(firstName: string, lastName: string) {
    return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
}
