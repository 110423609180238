import { useState } from 'react';
import { useTheme } from 'styled-components';
import { ComponentProps } from '../../../../common/types';
import * as Styles from './styles';
import { Steps } from 'antd';
import { Button } from '../../../../components/button';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as AlertIcon } from './icons/alert-circle.svg';

import PublishCampaign from '../publish-campaign/PublishCampaign';
import SelectPlatform from '../select-platform/SelectPlatform';
import CampaignDetails from '../campaign-details/CampaignDetails';
import { CampaignCreateAd } from '../campaign-create-ad/CampaignCreateAd';
import { ReviewCampaign } from '../review-campaign/ReviewCampaign';
import { useNavigate } from 'react-router-dom';

export interface CampaignStepperProps {
    active: number;
    setActive: any;
}

export function CampaignStepper({
    className,
    active,
    setActive,
}: ComponentProps<CampaignStepperProps>) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [final, setFinal] = useState<Boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>('');
    const nextPageMove = () => {
        if (active === 2) {
            setFinal(true);
        } else {
            setFinal(false);
        }
        setActive(active + 1);
    };
    const prevPageMove = () => {
        setFinal(false);
        setActive(active - 1);
    };
    const goPublish = () => {
        return;
    };

    const onHandleClose = () => {
        navigate('/');
    };

    return (
        <Styles.Container>
            <Styles.Head>
                <Styles.Title color={theme.palette.common.black}>Create New Campaign</Styles.Title>

                <Styles.Divider />
                <Styles.Stepper>
                    <Steps
                        progressDot
                        current={active}
                        initial={0}
                        items={[
                            {
                                title: '',
                            },
                            {
                                title: '',
                            },
                            {
                                title: '',
                            },
                            {
                                title: '',
                            },
                            {
                                title: '',
                            },
                        ]}
                    />
                </Styles.Stepper>
                <Styles.Divider />
                <Styles.Close onClick={onHandleClose}>
                    <CloseIcon />
                </Styles.Close>
            </Styles.Head>
            <Styles.Content>
                {active === -1 && <CampaignDetails />}
                {active === 0 && <SelectPlatform />}
                {active === 1 && <CampaignCreateAd imageUrl={imageUrl} setImageUrl={setImageUrl} />}
                {active === 2 && <ReviewCampaign imageUrl={imageUrl} setImageUrl={setImageUrl} />}
                {active === 3 && <PublishCampaign />}
            </Styles.Content>
            <Styles.Footer>
                <Button
                    onClick={() => prevPageMove()}
                    variant='tertiary'
                    id='back-button'
                    disabled={active === -1 ? true : false}
                >
                    Back
                </Button>
                {final === true && (
                    <>
                        <Styles.Text color={theme.palette.common.black}>
                            <Styles.Icon>
                                <AlertIcon />
                            </Styles.Icon>{' '}
                            By clicking Publish, you accept that you have read and agree to the
                            Service and Data Sharing Agreement
                        </Styles.Text>
                    </>
                )}
                <Styles.FooterBtn>
                    {final === false && (
                        <Button variant='secondary' id='reset-button'>
                            Reset
                        </Button>
                    )}
                    <Button
                        success={final === true}
                        id='submit-button'
                        disabled={active === 1 && !imageUrl ? true : false}
                        onClick={() => (final === true ? goPublish() : nextPageMove())}
                    >
                        {final === true ? 'Publish' : 'Next'}
                    </Button>
                </Styles.FooterBtn>
            </Styles.Footer>
        </Styles.Container>
    );
}
