import { DropdownTheme } from '../../../../models/theme/components';
import { palette } from '../palette';
import { roundRadius } from '../../../roundRadius';

export const dropdownTheme: DropdownTheme = {
    primary: {
        colors: {
            normal: {
                placeholder: palette.common.dustyGray,
                selectedValue: palette.text.selected,
                background: palette.common.white,
                border: palette.primary.main,
                icon: palette.primary.main,
            },
        },
        roundRadius: roundRadius.radius1,
    },
};
