import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { listReset, linkReset, displayContentCenter } from '../../common/styles/mixins';
import { layout } from '../../common/styles';
import { Theme } from '../../common/models/theme';
import {
    NavBarTheme,
    NavBarThemeColorVariants,
} from '../../common/models/theme/components/NavBarTheme';
import { Typography } from '../typography';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
export const linkHeight = 53;
export const linkVerticalOffset = 14;

export const Container = styled.div<{ isCollapsed: boolean }>`
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    background-color: ${({ theme }) => theme.palette.common.downriver};
    color: ${({ theme }) => theme.palette.common.gallery};
    flex-shrink: 0;
    width: ${(props) => (props.isCollapsed ? `${layout.navbarWidth}` : '50px')};
    z-index: 11;
`;

export const Arrow = styled(ArrowIcon)<{
    icondirection: boolean;
}>`
    transform: ${(props) => (props.icondirection ? 'rotate(180deg)' : '')};
`;

export const Header = styled.div`
    ${displayContentCenter}
    padding:13px 10px;
    height: 66px;
`;

export const NameContainer = styled.div`
    margin-left: 15px;
`;

export const FirstName = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 125px;
`;

export const ShowText = styled(Typography)`
    margin-left: 10px;
`;
export const ExpandControl = styled.div`
    border-top: 1px solid ${({ theme }) => theme.palette.common.cello};
    border-bottom: 1px solid ${({ theme }) => theme.palette.common.cello};
    margin: 0px 7px;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const Nav = styled.nav`
    display: flex;
    flex-flow: column;
    flex-grow: 1;
`;

export const SubMenuHeader = styled.div`
    padding: 14px 17px 5px 17px;
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
    line-height: 28px;
    border-bottom: 1px solid #fff;
`;

export const LinksList = styled.ul`
    ${listReset};
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    height: 100%;
    padding: 10px 0;
`;

export const Link = styled(NavLink)<{
    disabled?: boolean;
    isCollapsed?: boolean;
}>`
    ${linkReset};
    display: flex;
    align-items: center;
    padding: ${(props) => (props.isCollapsed ? '14px 26px 14px 10px' : '9px 10px')};
    cursor: pointer;
    border-left: 4px solid;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    ${({ theme }) => getStylesByVariantAndState(theme, 'primary', 'normal')}

    &:hover {
        ${({ theme }) => getStylesByVariantAndState(theme, 'primary', 'hover')}
    }

    &.active {
        ${({ theme }) => getStylesByVariantAndState(theme, 'primary', 'selected')}
    }

    &[disabled] {
        color: ${({ theme }) => theme.components.navBar.primary.colors.normal.text};
    }
`;

export const LinkListItem = styled.li`
    padding: 5px 0px;
    position: relative;
    &:last-child {
        margin-top: auto;
        &::before {
            position: absolute;
            content: '';
            height: 1px;
            width: 73%;
            background: ${({ theme }) => theme.palette.common.cello};
            left: 7px;
            top: -10px;
        }
    }
`;

export const NavLinkIcon = styled.div``;

export const NavLinkLabel = styled(Typography)`
    margin-left: 12px;
`;

export const NavSubItems = styled.div<{
    subItemsHeight: number;
    subMenuState: boolean;
}>`
    overflow: hidden;
    box-shadow: ${({ theme }) => theme.shadows.style7};
    border-radius: ${({ theme }) => theme.roundRadius.radius2};
    position: absolute;
    left: 100%;
    margin-left: ${({ theme }) => theme.spacing.form.spacing3};
    background-color: ${({ theme }) => theme.palette.common.downriver};
    min-width: 200px;
    top: 5px;
    transition: 0.2s ease-out;
    height: ${(props) => (props.subMenuState ? '200px' : '0px')};
    ${Link} {
        border-left: 0px;
        margin: 10px 0px;
    }
`;

export const SubMenuContainer = styled.div``;

function getStylesByVariantAndState(
    theme: Theme,
    navBarVariant: keyof NavBarTheme,
    state: keyof NavBarThemeColorVariants
) {
    return css`
        border-color: ${theme.components.navBar[navBarVariant].colors[state].border};
        background-color: ${theme.components.navBar[navBarVariant].colors[state].background};
        color: ${theme.components.navBar[navBarVariant].colors[state].text};
        ${NavLinkIcon} {
            fill: ${theme.components.navBar[navBarVariant].colors[state].icon};
        }
    `;
}
