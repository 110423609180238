import React, { useState } from 'react';
import * as Styles from './styles';
import { ReactComponent as PenIcon } from './icons/pen.svg';
import facebook from './icons/facebook.png';
import { RadioButton } from '../../../../components/radio';
import { FormControl } from '../../../../components/form-control';
import { Input } from '../../../../components/input';
import { ComponentProps } from '../../../../common/types';
import { Upload } from 'antd';
import { InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

const { Dragger } = Upload;

export interface CampaignAdProps {
    imageUrl: string;
    setImageUrl: any;
}
export function ReviewCampaign({
    imageUrl,
    setImageUrl
}: ComponentProps<CampaignAdProps>) {
    const [loading, setLoading] = useState(false);
    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
      };
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
          getBase64(info.file.originFileObj as RcFile, (url) => {
            setLoading(false);
            setImageUrl(url);
          });
        }
      };
    return (
        <Styles.Container>
            <Styles.HeaderContainer>
                <div>
                    <Styles.TopTitle>Review Campaign</Styles.TopTitle>
                    <Styles.SubTitle>Review materials before publish</Styles.SubTitle>
                </div>
                
            </Styles.HeaderContainer>
            <Styles.Divider />
            <Styles.SelectContainer>
                    <Styles.LeftContainer>
                        <Styles.RadioContainer>
                        <Styles.InputConatiner>
                            <div>Campaign Name</div>
                            <Input value='15% off Melts - Poster' icon={<PenIcon />} iconPosition='right' />
                        </Styles.InputConatiner>
                        <Styles.InputConatiner>
                            <div>Campaign Objective</div>
                            <Input value='Day Part Optimization' icon={<PenIcon />} iconPosition='right' />
                        </Styles.InputConatiner>
                        <Styles.CardContainer>
                            <label>Selected Platform <span><PenIcon /></span></label>
                            
                            <Styles.Card>
                                <Styles.Image>
                                    <img src={facebook} />
                                </Styles.Image>
                                <Styles.Content>
                                    <Styles.Label>Facebook</Styles.Label>
                                </Styles.Content>
                            </Styles.Card>
                        </Styles.CardContainer>
                        <Styles.Select>
                            <Styles.SelectContainer>
                                <Styles.InputConatiner>
                                    <div>Campaign Budget</div>
                                    <Input value='$ 2000' icon={<PenIcon />} iconPosition='right' />
                                </Styles.InputConatiner>
                                <Styles.InputConatiner>
                                    <div>Daily Budget</div>
                                    <Input value='$ 20' icon={<PenIcon />} iconPosition='right' />
                                </Styles.InputConatiner>
                            </Styles.SelectContainer>
                            <Styles.SelectContainer>
                                <Styles.InputConatiner>
                                    <div>Buying Type</div>
                                    <Input value='Auction' icon={<PenIcon />} iconPosition='right' />
                                </Styles.InputConatiner>
                                <Styles.InputConatiner>
                                    <div>Bid Strategy</div>
                                    <Input value='High volume' icon={<PenIcon />} iconPosition='right' />
                                </Styles.InputConatiner>
                            </Styles.SelectContainer>
                           
                        </Styles.Select>
                        </Styles.RadioContainer>
                    </Styles.LeftContainer>
                    <Styles.UploadContainer>
                    <Styles.Title>Ad Creative <PenIcon /></Styles.Title>
                    <Styles.SubTitle>
                        Preview ad creative based on selected placements.
                    </Styles.SubTitle>
                    <Styles.UploadBox>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        disabled={true}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        onChange={handleChange}
                    >
                        {imageUrl && <Styles.ImageView><img src={imageUrl} alt="avatar" /></Styles.ImageView>}
                    </Upload>
                    {/*  <Dragger >
                        <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Drag & Drop Ad file here</p>
                        <p className="ant-upload-hint">
                        - or -
                        </p>
                        <p className="ant-upload-hint">
                        Browse File
                        </p>
                    </Dragger> */}
                    </Styles.UploadBox>
                    </Styles.UploadContainer>
            </Styles.SelectContainer>
        </Styles.Container>
    );
}

