import styled from 'styled-components';
import { CircleLoader } from '../circle-loader';
import { LoadableProps } from './Loadable';

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
`;

export const MainBlurableContainer = styled.div`
    width: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
`;

export const BlurableContainer = styled.div<LoadableProps>`
    width: 100%;
    min-height: 100%;
    filter: ${(props) => props.loading && 'blur(5px)'};
`;

export const BlurableCircleLoader = styled(CircleLoader)`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
`;
