import { Shadows } from '../models/theme';

export const shadows: Shadows = {
    none: 'none',
    cards: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    style1: '0 1px 2px rgb(0 0 0 / 10%)',
    style2: '0 2px 4px rgb(0 0 0 / 10%)',
    style3: '2px 2px 4px rgb(0 0 0 / 10%)',
    style4: '4px 4px 8px rgb(0 0 0 / 10%)',
    style5: '4px 4px 16px rgb(0 0 0 / 10%)',
    style6: '2px 2px 4px rgba(0 0 0 / 16%)',
    style7: '0px 1px 2px rgb(0 0 0 /12%)',
    style8: '0px 1px 1px rgb(0 0 0 / 10%)',
    style9: '0px 2px 2px rgba(0 0 0 /12%)',
};
